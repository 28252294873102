import GLOBAL from '@/config/global'
import common from './common'
import policy from './policy'
import supplier from './supplier'
import cases from './case'
import product from './product'
import park from './park'
import company from './company'
import supportRecord from './supportRecord'
// 根据权限访问的路由
const asyncRouter = []
if (!GLOBAL.gateway) {
  // 非网关模式，每个路由菜单在此累加
  asyncRouter.push(common)
  asyncRouter.push(policy)
  asyncRouter.push(supplier)
  asyncRouter.push(cases)
  asyncRouter.push(product)
  asyncRouter.push(park)
  asyncRouter.push(company)
  asyncRouter.push(supportRecord)
  asyncRouter.push({
    path: '*',
    redirect: '/404',
    meta: {
      hidden: true
    }
  })
}
export const asyncRouterMap = asyncRouter
