// 路由引用的组件，meta标签覆盖。目前支持cache、closable、hidden、nav、iframe、iframeUrl
const codeMap = {
  // welcome 页面不支持关闭
  welcome: {
    hidden: true
  },
  ProjectDetail: {
    cache: false
  },
  taskPlatformManage: {
    closable: false,
    cache: true
  },
  PendingProjectList: {
    hidden: true
  }
}
export default codeMap
