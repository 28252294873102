import Layout from '@/modules/layout/Layout.vue'
// 基础数据路由
export default {
  path: '/productManage',
  name: 'productManage',
  component: Layout,
  redirect: 'noredirect',
  icon: 'fa fa-envelope-o',
  meta: {
    label: '商品管理'
  },
  children: [
    {
      path: 'productList',
      name: 'productList',
      component: () => import('@/views/productManage/productList.vue'),
      meta: {
        label: '商品列表', // 标题
        closable: true
      }
    },
    {
      path: 'productRecommendList',
      name: 'productRecommendList',
      component: () => import('@/views/productManage/productRecommendList.vue'),
      meta: {
        label: '商品推荐列表',
        closable: true
      }
    }
  ]
}
