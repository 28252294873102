<template>
  <header class="zto-header">
    <div class="header-left">
      <div class="logo-div">
        <img class="logo-img" :src="logo">
      </div>
      <el-tabs v-model="currentNav" @tab-click="handleClick">
        <el-tab-pane v-for="item in navArr" :label="item.label" :name="item.prop" :key="item.prop">{{item.label}}</el-tab-pane>
      </el-tabs>
    </div>
    <div class="header-right">
      <el-popover placement="bottom" popper-class="user-popover" trigger="hover">
        <div class="user-info">
          <img class="user-avatar" :src="userInfo.avatar?userInfo.avatar:avatar" />
          <div class="user-name">{{userInfo.fullname}}</div>
          <div class="user-dept">{{userInfo.deptName}}</div>
          <div class="user-date">{{curDate}}</div>
        </div>
        <div class="user-svg" slot="reference">
          <svg-icon icon-class="yonghu"></svg-icon>
        </div>
      </el-popover>
      <div class="vertical-line"></div>
      <div class="sys-logout" @click="logoutAction">
        <svg-icon icon-class="guanbi"></svg-icon>
      </div>
    </div>
  </header>
</template>

<script>
import logo from '@/assets/layout/logo-new.svg'
import avatar from '@/assets/layout/avatar-1.jpg'
import { mapGetters, mapActions } from 'vuex'
import { util } from '@/utils/util'
export default {
  name: 'ztoNewHeader',
  data() {
    return {
      logo: logo,
      avatar: avatar,
      curDate: util.dateToStr(new Date(), 1),
      currentNav: ''
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      navArr: 'navArr',
      curNav: 'curNav'
    })
  },
  mounted() {
    this.currentNav = this.curNav
  },
  watch: {
    curNav() {
      this.currentNav = this.curNav
    }
  },
  methods: {
    ...mapActions(['setCurNav', 'clearZtoSession']),
    handleClick(tab, event) {
      this.setCurNav(tab.name)
    },
    logoutAction() {
      this.$confirm('您确定要退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.clearZtoSession().then().finally(async() => {
            try {
              // 网关模式退出接口，会返回 res.statusCode '301'。不需要手动window.location.reload()
              if (!this.GLOBAL.gateway) window.location.reload()
            } catch (err) {
              if (!this.GLOBAL.gateway) window.location.reload()
            }
          })
        }, () => {
        })
    }
  }
}
</script>
