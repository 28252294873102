// 此处可以import api
import GLOBAL from '@/config/global'
import httpService from '@/api/httpService'
import apiUrl from '@/api/apiUrl'
import { tool } from '@/router/tool'
import { filterAsyncRouter } from '@/utils/permission' // 权限判断函数
import { asyncRouterMap } from '@/router/main/index'
import { asyncRouterUserMap } from '@/router/main/index_user'
import { getCookie, setCookie, removeCookie } from '@/utils/cookie'
let routeId = 0 // 路由Id
let permissions = [] // 菜单、操作、父菜单权限集
let routeArr = [] // 所有路由数组

let addRouters = [] // 需要添加的路由
const Types = {
  SET_USER_INFO: 'SET_USER_INFO',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_MENU: 'SET_MENU',
  SET_MENU_GATEWAY: 'SET_MENU_GATEWAY',
  SET_CUR_NAV: 'SET_CUR_NAV',
  SET_NAV_OPEN: 'SET_NAV_OPEN',
  SET_ROLE: 'SET_ROLE',
}
const state = {
  userInfo: {
    avatar: '',
    certLevel: '',
    deptCode: '',
    deptId: '',
    deptName: '',
    fullname: '',
    moodMessage: '',
    nickname: '',
    isTechCenterDept: false,
    nodeCode: '',
    nodeId: '',
    nodeName: '',
    openid: '',
    permissions: [],
    menus: {
      default: [],
      test: []
    }
  },
  // 导航菜单列表
  navArr: [
    {
      prop: 'default',
      label: '默认菜单'
    },
    {
      prop: 'test',
      label: '测试菜单'
    }
  ],
  defaultNav: 'default', // 导航菜单默认选项
  curNav: 'default', // 导航菜单当前选项
  navOpen: GLOBAL.navOpen,
  roleName: getCookie('roleName'),
  routeDeep: 2 // 路由的深度
}
const getters = {
  userInfo: state => state.userInfo,
  permissions: state => state.userInfo.permissions,
  navOpen: state => state.navOpen,
  navArr: state => state.navArr,
  defaultNav: state => state.defaultNav,
  curNav: state => state.curNav,
  roleName: state => state.roleName
}
const actions = {
  async login({commit}, params) {
    const opts = {
      apiObj: apiUrl.login,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    commit(Types.SET_ROLE, { roleName: data.roleName })
    commit(Types.SET_PERMISSIONS, ['login', 'register'])
    commit(Types.SET_MENU)
  },
  // register 用户
  async register({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.saveSupplier,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 刷新重新设置路由
  async reSetRouter({ commit }) {
    commit(Types.SET_PERMISSIONS, ['login', 'register'])
    commit(Types.SET_MENU)
    return routeArr
  },
  // 获取用户信息
  async getUserInfo({ commit }) {
    // commit(Types.SET_PERMISSIONS, ['login', 'register'])
    // commit(Types.SET_MENU)
    return routeArr
  },
  // 切换开启导航菜单
  setNavOpen({ commit }, params) {
    return new Promise((resolve) => {
      commit(Types.SET_NAV_OPEN, params)
      resolve()
    })
  },
  // 设置当前导航菜单
  setCurNav({ commit }, params) {
    return new Promise((resolve) => {
      commit(Types.SET_CUR_NAV, params)
      resolve()
    })
  },
  // 退出后端session
  async clearZtoSession({ commit}) {
    routeId = 0 // 路由Id
    permissions = ['login', 'register'] // 菜单、操作、父菜单权限集
    routeArr = [] // 所有路由数组
    commit(Types.SET_ROLE, { roleName: '' })
    removeCookie('roleName')
  },
  // 获取公告
  async getNotice() {
    const opts = {
      apiObj: apiUrl.getNotice
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  }
}
const mutations = {
  [Types.SET_ROLE]: (state, params) => {
    state.roleName = params.roleName
    setCookie('roleName', params.roleName)
  },
  [Types.SET_CUR_NAV]: (state, params) => {
    state.curNav = params || state.defaultNav
  },
  [Types.SET_NAV_OPEN]: (state) => {
    state.navOpen = !state.navOpen
  },
  [Types.SET_USER_INFO]: (state, params) => {
    state.userInfo.avatar = params.avatar
    state.userInfo.certLevel = params.certLevel
    state.userInfo.deptCode = params.deptCode
    state.userInfo.deptId = params.deptId
    state.userInfo.deptName = params.deptName
    state.userInfo.fullname = params.fullName
    state.userInfo.moodMessage = params.moodMessage
    state.userInfo.nickname = params.nickName
    state.userInfo.nodeCode = params.nodeCode
    state.userInfo.nodeId = params.nodeId
    state.userInfo.nodeName = params.nodeName
    state.userInfo.openid = params.openId
    state.userInfo.userId = params.userId.toString()
    state.userInfo.isTechCenterDept = params.isTechCenterDept
  },
  [Types.SET_PERMISSIONS]: (state, result) => {
    let asyncRouter = asyncRouterMap
    if (state.roleName === 'ROLE_NORMAL') {
      asyncRouter = asyncRouterUserMap
    }
    preRouteArr(asyncRouter, 0)
    for (var v of result) {
      fillPerms(v, routeArr)
    }
    for (var v2 of result) {
      permissions.push(v2)
    }
    permissions = Array.from(new Set([...permissions]))
    state.userInfo.permissions = permissions
    addRouters = filterAsyncRouter(asyncRouter, permissions)
    var routeDeep = tool.getFloor(addRouters)
    routeDeep = routeDeep === 1 ? 2 : routeDeep
    state.routeDeep = routeDeep
  },
  [Types.SET_MENU]: (state) => {
    state.userInfo.menus = []
    for (var v of state.navArr) {
      const menuObj = {
        id: 0,
        subMenus: []
      }
      menuTree(menuObj, routeArr, v.prop)
      state.userInfo.menus[v.prop] = menuObj.subMenus
    }
  },
  [Types.SET_MENU_GATEWAY]: (state, params) => {
    tool.filterMenu(params)
    for (var v1 of state.navArr) {
      var menus = []
      for (var v2 of params) {
        if (v1.prop === v2.nav) {
          menus.push(v2)
        }
      }
      state.userInfo.menus[v1.prop] = menus
    }
    var routeDeep = tool.getFloorRefer(params)
    routeDeep = routeDeep === 1 ? 2 : routeDeep
    state.routeDeep = routeDeep
    for (var v3 of params) {
      var obj = {}
      var max = tool.getFloor(v3.children)
      tool.buildRoutes(v3, obj, 1, max + 1, routeDeep)
      addRouters.push(obj)
    }
    addRouters.push({ path: '*', redirect: '/404', meta: { hidden: true }})
  }
}
export default {
  state,
  actions,
  mutations,
  getters
}

// 整理所有的路由 prop
function preRouteArr(routes, pId) {
  for (const v of routes) {
    routeId++
    routeArr.push({
      id: routeId,
      pId: pId,
      title: v.meta && v.meta.label,
      role: v.meta && v.meta.role,
      hidden: v.meta && v.meta.hidden,
      noMe: v.meta && v.meta.noMe,
      nav: v.meta && v.meta.nav,
      name: v.name,
      icon: v.icon,
      subMenus: [],
      isItem: !(v.children && v.children.length > 0)
    })
    if (v.children && v.children.length > 0) {
      preRouteArr(v.children, routeId)
    }
  }
}
// 递归向上，统计所有菜单权限
function fillPerms(role, routeArr) {
  for (const v of routeArr) {
    if (role === v.role) {
      permissions.push(v.role)
      for (const v2 of routeArr) {
        if (v.pId === v2.id) {
          if (v2.role) {
            permissions.push(v2.role)
            fillPerms(v2.role, routeArr)
          }
        }
      }
    }
  }
}
// 生成菜单树
function menuTree(obj, routeArr, prop) {
  for (const v of routeArr) {
    if (v.pId === obj.id && (permissions.indexOf(v.role) >= 0 || !v.role) && !v.hidden) {
      if (prop === state.defaultNav && (!v.nav || v.nav === state.defaultNav)) {
        obj.subMenus.push(menuTree(v, routeArr, prop))
      }
      if (prop !== state.defaultNav && prop === v.nav) {
        obj.subMenus.push(menuTree(v, routeArr, prop))
      }
    }
  }
  return obj
}
