import Layout from '@/modules/layout/Layout.vue'
import Nest2 from '@/modules/layout/Nest2'
// 通用页面
export const constantRouterMap = [
  {
    path: '/login', // 登陆页
    component: () => import('@/views/common/Login')
  },
  {
    path: '/register', // 注册页
    component: () => import('@/views/common/register')
  },
  {
    path: '/403', // 无权限页面
    component: () => import('@/views/common/errorPages/403')
  },
  {
    path: '/404', // 404页面
    component: () => import('@/views/common/errorPages/404')
  },
  {
    path: '/500', // 服务器错误
    component: () => import('@/views/common/errorPages/500')
  },
  { path: '',
    component: Layout,
    redirect: 'noredirect',
    meta: {
      hidden: true,
      role: 'me_common'
    },
    children: [
      {
        path: '',
        component: Nest2,
        meta: { noMe: true, role: 'me_welcomeP' },
        children: [
          {
            path: '/welcome',
            name: 'welcome',
            component: () => import('@/views/common/Welcome'),
            meta: {
              label: '主页', // 标题
              cache: true, // 是否缓存
              closable: false, // 是否标签可关闭
              role: 'me_welcome'
            }
          }
        ]
      },
      {
        path: '',
        component: Nest2,
        children: [
          {
            path: '/policyManage/addPolicy',
            name: 'addPolicy',
            component: () => import('@/views/policyManage/addPolicy'),
            meta: {
              label: '新增资讯', // 标题
              closable: true
            }
          },
          {
            path: '/policyManage/policyList',
            name: 'policyList',
            component: () => import('@/views/policyManage/policyList.vue'),
            meta: {
              label: '资讯列表',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/policyManage/policyDetail/:policyId',
            name: 'policyDetail',
            component: () => import('@/views/policyManage/policyDetail.vue'),
            meta: {
              label: '资讯详情',
              cache: false,
              closable: true,
              noMe: true
            }
          }
        ]
      },
      {
        path: '',
        component: Nest2,
        children: [
          {
            path: '/supplierManage/supplierList',
            name: 'supplierList',
            component: () => import('@/views/supplierManage/supplierList.vue'),
            meta: {
              label: '供应商列表',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/supplierManage/supplierDetail/:supplierId',
            name: 'supplierDetail',
            component: () => import('@/views/supplierManage/supplierDetail.vue'),
            meta: {
              label: '供应商详情',
              cache: false,
              closable: true,
              noMe: true
            }
          }
        ]
      },
      {
        path: '',
        component: Nest2,
        children: [
          {
            path: '/caseManage/caseList',
            name: 'caseList',
            component: () => import('@/views/caseManage/caseList.vue'),
            meta: {
              label: '剧组备案列表',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/caseManage/caseDetail/:caseId',
            name: 'caseDetail',
            component: () => import('@/views/caseManage/caseDetail.vue'),
            meta: {
              label: '剧组备案详情',
              cache: false,
              closable: true,
              noMe: true
            }
          }
        ]
      },
      {
        path: '',
        component: Nest2,
        children: [
          {
            path: '/productManage/productList',
            name: 'productList',
            component: () => import('@/views/productManage/productList.vue'),
            meta: {
              label: '商品列表',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/productManage/productDetail/:productId',
            name: 'productDetail',
            component: () => import('@/views/productManage/productDetail.vue'),
            meta: {
              label: '商品详情',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/productManage/productRecommendList',
            name: 'productRecommendList',
            component: () => import('@/views/productManage/productRecommendList.vue'),
            meta: {
              label: '商品推荐列表',
              cache: false,
              closable: true,
              noMe: true
            }
          },
        ]
      },
      {
        path: '',
        component: Nest2,
        children: [
          {
            path: '/parkManage/parkList',
            name: 'parkList',
            component: () => import('@/views/parkManage/parkList.vue'),
            meta: {
              label: '园区列表',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/parkManage/parkDetail/:parkId',
            name: 'parkDetail',
            component: () => import('@/views/parkManage/parkDetail.vue'),
            meta: {
              label: '园区详情',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/parkManage/parkNewsList',
            name: 'parkNewsList',
            component: () => import('@/views/parkManage/parkNewsList.vue'),
            meta: {
              label: '园区动态列表',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/parkManage/parkNewsDetail/:parkNewsId',
            name: 'parkNewsDetail',
            component: () => import('@/views/parkManage/parkNewsDetail.vue'),
            meta: {
              label: '园区动态详情',
              cache: false,
              closable: true,
              noMe: true
            }
          }
        ]
      },
      {
        path: '',
        component: Nest2,
        children: [
          {
            path: '/companyManage/companyList',
            name: 'companyList',
            component: () => import('@/views/companyManage/companyList.vue'),
            meta: {
              label: '重点企业列表',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/companyManage/companyDetail/:companyId',
            name: 'companyDetail',
            component: () => import('@/views/companyManage/companyDetail.vue'),
            meta: {
              label: '重点企业详情',
              cache: false,
              closable: true,
              noMe: true
            }
          }
        ]
      },
      {
        path: '',
        component: Nest2,
        children: [
          {
            path: '/supportRecordManage/supportRecordList',
            name: 'supportRecordList',
            component: () => import('@/views/supportRecordManage/supportRecordList.vue'),
            meta: {
              label: '扶持记录列表',
              cache: false,
              closable: true,
              noMe: true
            }
          },
          {
            path: '/supportRecordManage/supportRecordDetail/:supportRecordId',
            name: 'supportRecordDetail',
            component: () => import('@/views/supportRecordManage/supportRecordDetail.vue'),
            meta: {
              label: '扶持记录详情',
              cache: false,
              closable: true,
              noMe: true
            }
          }
        ]
      }
    ]
  }
]
