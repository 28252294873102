import Layout from '@/modules/layout/Layout.vue'
// 基础数据路由
export default {
  path: '/parkManage',
  name: 'parkManage',
  component: Layout,
  redirect: 'noredirect',
  icon: 'fa fa-envelope-o',
  meta: {
    label: '园区管理'
  },
  children: [
    {
      path: 'parkList',
      name: 'parkList',
      component: () => import('@/views/parkManage/parkList.vue'),
      meta: {
        label: '园区列表', // 标题
        closable: true
      }
    },
    {
      path: 'parkNewsList',
      name: 'parkNewsList',
      component: () => import('@/views/parkManage/parkNewsList.vue'),
      meta: {
        label: '园区动态列表', // 标题
        closable: true
      }
    }
  ]
}
