import store from '@/store/store'
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export const checkPerm = function(value) {
  if (value && value instanceof Array && value.length > 0) {
    const perms = store.getters && store.getters.permissions
    // const permissionRoles = value

    const hasPermission = perms.some(perm => {
      return value.includes(perm)
    })

    if (!hasPermission) {
      return false
    }
    return true
  } else {
    console.error(`need roles! Like v-if="checkPerm['op_editor']"`)
    return false
  }
}

function hasPermission(roles, route) {
  if (route.meta && route.meta.role) {
    return roles.some(role => route.meta.role === role)
  } else {
    return true
  }
}

export const filterAsyncRouter = function(asyncRouterMap, roles) {
  const accessedRouters = asyncRouterMap.filter(route => {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles)
      }
      return true
    }
    return false
  })
  return accessedRouters
}
