/*
 * 所有表单需要的验证规则
 * 复杂的验证规则在util中的validate.js可以创建符合返回callback的函数
 * 只要el-form-item prop等于以下的验证规则并且开启表单的规则验证:rules=指定validateXXX"即可
 * 为了防止污染页面上的data减少创建不必要数据建议创建多个常量来引入到指定的页面中
 */

import { util } from '../utils/util'

function validateNoFourBytesChar(message) {
  return function(rule, value, callback) {
    if (util.containFourBytesChar(value)) {
      callback(new Error(message))
    } else {
      callback()
    }
  }
}

function validateNotAllEmptyChar(message) {
  return function(rule, value, callback) {
    if (value && value.trim()) {
      callback()
    } else {
      callback(message)
    }
  }
}

export const validateLogin = {
  password: [{ required: true, message: '密码必须填写', trigger: 'blur' }],
  userName: [{ required: true, message: '用户名不能为空', trigger: 'blur' }]
}

const basicRequirementInfo = {
  name: [{
    required: true, message: '请输入需求标题', trigger: 'change'
  }, {
    max: 120, message: '标题最长120个字符', trigger: 'change'
  }],
  targetUser: [{
    max: 200, message: '目标用户最长200个字符', trigger: 'change'
  }],
  type: [{
    required: true, message: '请选择需求类型', trigger: 'change'
  }],
  priority: [{
    required: true, message: '请选择优先级', trigger: 'change'
  }],
  dockingDepartmentId: [{
    required: true, message: '请选择对接部门', trigger: 'change'
  }],
  dockingManCode: [{
    required: true, message: '请选择对接人', trigger: 'change'
  }],
  expectEndTime: [{
    required: true, message: '请选择上线时间', trigger: 'change'
  }],
  coreProductFlg: [{
    required: true, message: '是否中心核心产品', trigger: 'change'
  }],
  solveDifficultFlg: [{
    required: true, message: '是否解决中心目前的技术痛点', trigger: 'change'
  }],
  fillBlankFlg: [{
    required: true, message: '是否填补中心现有的技术空白', trigger: 'change'
  }],
  technologyCreateFlg: [{
    required: true, message: '是否技术创新', trigger: 'change'
  }],
  advanceWorthFlg: [{
    required: true, message: '是否促进提升业务价值', trigger: 'change'
  }]
}

export const basicRejectRule = {
  rejectMessage: [{
    required: true, message: '请输入意见', trigger: 'change'
  }, {
    max: 500, message: '意见最长500个字符', trigger: 'change'
  }]
}

const basicCirculateRule = {
  departId: [{
    required: true, message: '请选择对接部门', trigger: 'change'
  }],
  userId: [{
    required: true, message: '请选择对接人', trigger: 'change'
  }]
}

export const parentRequirementRule = {
  ...basicRequirementInfo,
  coreProductFlg: [{
    required: false, message: '是否中心核心产品', trigger: 'change'
  }],
  solveDifficultFlg: [{
    required: false, message: '是否解决中心目前的技术痛点', trigger: 'change'
  }],
  fillBlankFlg: [{
    required: false, message: '是否填补中心现有的技术空白', trigger: 'change'
  }],
  technologyCreateFlg: [{
    required: false, message: '是否技术创新', trigger: 'change'
  }],
  advanceWorthFlg: [{
    required: false, message: '是否促进提升业务价值', trigger: 'change'
  }]
}

export const subRequirementRule = {
  ...basicRequirementInfo
}

export const assessmentRequirementRule = {
  ...basicRequirementInfo,
  ...{
    imputedValue: [{
      required: true, message: '请输入价值估算', trigger: 'blur'
    }],
    manDay: [{
      required: true, message: '请输入人天估算', trigger: 'blur'
    }],
    createDepartmentName: [{
      required: true, message: '提出部门不能为空', trigger: 'change'
    }],
    createUserName: [{
      required: true, message: '提出人员不能为空', trigger: 'change'
    }]
  }
}
export const assessmentOpinionRule = {
  imputedValue: [{
    required: true, message: '请输入价值估算', trigger: 'change'
  }],
  isCenter: [{
    required: true, message: '请选择是否是中心', trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选择所属产品', trigger: 'change'
  }]
}
export const techAssessmentOpinionRule = {
  isCenter: [{
    required: true, message: '请选择是否是中心核心产品', trigger: 'change'
  }],
  resolveIssue: [{
    required: true, message: '请选择是否解决中心目前的技术痛点', trigger: 'change'
  }],
  emptyTechnology: [{
    required: true, message: '请选择是否填补中心现有的技术空白', trigger: 'change'
  }],
  newTechnology: [{
    required: true, message: '请选择是否技术创新', trigger: 'change'
  }],
  promoteLevel: [{
    required: true, message: '请选择是否促进提升业务价值', trigger: 'change'
  }],
  requirementLevel: [{
    required: true, message: '请选择需求等级', trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选择所属产品', trigger: 'change'
  }]
}

export const reviewResolveRule = {
  reviewMessage: [{
    required: true, message: '请输入评审意见', trigger: 'change'
  }, {
    max: 500, message: '评审意见最长500个字符', trigger: 'change'
  }]
}

export const reviewFocusRule = {
  focusMessage: [{
    required: true, message: '请输入对焦会建议', trigger: 'change'
  }, {
    max: 500, message: '对焦会建议最长500个字符', trigger: 'change'
  }]
}
export const closeRequirementRule = {
  closeMessage: [{
    required: true, message: '请输入关闭理由', trigger: 'change'
  }, {
    max: 120, message: '关闭理由最长500个字符', trigger: 'change'
  }]
}

// 2.14.0 范家宇 挂起规则
export const hanngUpRequirementRule = {
  hangUpMessage: [{
    required: true, message: '请输入挂起原因', trigger: 'change'
  }, {
    max: 120, message: '关闭理由最长200个字符', trigger: 'change'
  }]
}

export const circulateRule = {
  ...basicCirculateRule
}

export const adoptRejectRule = {
  ...basicRejectRule
}

export const storyRule = {
  name: [{
    required: true, message: '请输入标题', trigger: 'change'
  }, {
    max: 120, message: '标题最长120个字符', trigger: 'change'
  }],
  demandId: [{ required: true, message: '请选择需求', trigger: 'change' }],
  demandDesc: [{
    required: true, message: '请输入需求描述', trigger: 'change'
  }],
  jiraDepartmentId: [{
    required: true, message: '请选择jira部门', trigger: 'change'
  }],
  productLineId: [{
    required: true, message: '请选择产品线', trigger: 'change'
  }],
  jiraProjectId: [{
    required: true, message: '请选择jira项目', trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选择产品', trigger: 'change'
  }],
  priority: [{
    required: true, message: '请选择优先级', trigger: 'change'
  }],
  jiraStoryEndTime: [{
    required: true, message: '请选择期待结束时间', trigger: 'change'
  }]
}

export const jiraVersionRule = {
  jiraDepartmentId: [{
    required: true, message: '请选择jira部门', trigger: 'change'
  }],
  jiraProjectId: [{
    required: true, message: '请选择jira项目', trigger: 'change'
  }],
  jiraVersionId: [{
    required: true, message: '请选择版本号', trigger: 'change'
  }]
}

export const pmsVersionRule = {
  productLineId: [{
    required: true, message: '请选择jira部门', trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选择jira项目', trigger: 'change'
  }],
  version: [{
    required: true, message: '请选择版本号', trigger: 'change'
  }]
}

export const addressRule = {
  name: [{
    required: true, message: '请输入文档名称'
  }],
  url: [{
    required: true, message: '请输入文档名称'
  }]
}

export const projectAddRule = {
  name: [{
    required: true, message: '请输入项目名称', trigger: 'change'
  }],
  descs: [{
    required: true, message: '请输入项目描述', trigger: 'change'
  }],
  type: [{
    required: true, message: '请选择项目类型', trigger: 'change'
  }],
  dutyerId: [{
    required: true, message: '请选择项目负责人', trigger: 'change'
  }],
  projecterId: [{
    required: true, message: '请选择主项目经理', trigger: 'change'
  }],
  producterId: [{
    required: true, message: '请选择主产品经理', trigger: 'change'
  }],
  developerId: [{
    required: true, message: '请选择主开发经理', trigger: 'change'
  }],
  testerId: [{
    required: true, message: '请选择主测试经理', trigger: 'change'
  }],
  planStartTime: [{
    required: false, message: '请选择计划开始时间', trigger: 'change'
  }],
  planEndTime: [{
    required: false, message: '请选择计划结束时间', trigger: 'change'
  }]
}
// 部门级校验规则
export const departmentProjectEditRule = Object.assign({}, projectAddRule)
// 中心级校验规则
export const centerProjectEditRule = Object.assign({
  architectId: [{
    required: true, message: '请选择架构师', trigger: 'change'
  }]
}, departmentProjectEditRule)

/* 中心项目启动启动规则 */
export const centerProjectStartRule = {
  productAuditTime: [{
    required: true, message: '请输入产品评审计划时间', trigger: 'change'
  }],
  architectAuditTime: [{
    required: true, message: '请输入架构评审计划时间 ', trigger: 'change'
  }],
  onlineTime: [{
    required: true, message: '请输入上线完成计划时间', trigger: 'change'
  }],
  checkedTime: [{
    required: true, message: '请输入结项完成计划时间', trigger: 'change'
  }],
  recoveryTime: [{
    required: true, message: '请输入价值复盘计划时间', trigger: 'change'
  }]
}
/* 部门项目启动启动规则 */
export const departmentProjectStartRule = Object.assign({
  architectId: [{
    required: true, message: '请选择架构师', trigger: 'change'
  }]
}, centerProjectStartRule)
export const milestoneRule = {
  stageName: [{
    required: true, message: '请输入里程碑名称', trigger: 'change'
  }],
  stageDate: [{
    required: true, message: '请选择计划完成时间', trigger: 'change'
  }]
}

export const productReviewRule = {
  desc: [{
    required: true, message: '请输入评审意见', trigger: 'change'
  }],
  hasAdopt: [{
    required: true, message: '请选择', trigger: 'change'
  }]
}
export const architectReviewRule = {
  message: [{
    required: true, message: '请输入架构评审意见', trigger: 'change'
  }]
}

export const filterShortcutRule = {
  name: [{
    required: true, message: '请输入筛选条件名称', trigger: 'change'
  }]
}

export const feedbackRule = {
  descs: [{
    required: true, message: '请输入反馈内容', trigger: 'change'
  }]
}
export const scoreRule = {
  targetGrade: [{
    required: true, message: '请输入评分', trigger: 'change'
  }],
  understandGrade: [{
    required: true, message: '请输入评分', trigger: 'change'
  }],
  typeGrade: [{
    required: true, message: '请输入评分', trigger: 'change'
  }],
  proposal: [{
    required: true, message: '请输入意见', trigger: 'change'
  }]
}

export const scoreSecondVersionRule = {
  grade: [{
    required: true, message: '请输入评分', trigger: 'change'
  }],
  proposal: [{
    required: true, message: '请输入意见', trigger: 'change'
  }]
}

export const versionRule = {
  versionName: [{
    required: true, message: '请输入版本号', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本号'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本号不能包含特殊字符'),
    trigger: 'change'
  }],
  versionDesc: [{
    required: true, message: '请输入版本描述', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本描述'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本描述不能包含特殊字符'),
    trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选产品', trigger: 'change'
  }],
  type: [{
    required: true, message: '请选择版本类型', trigger: 'change'
  }],
  testerId: [{
    required: true, message: '请输入测试负责人', trigger: 'change'
  }],
  demandReviewDate: [{
    required: true, message: '请选择需求评审日期', trigger: 'change'
  }],
  technicianReviewDate: [{
    required: true, message: '请选择技术评审时间', trigger: 'change'
  }],
  developDate: [{
    required: true, message: '请选择开发时间', trigger: 'change'
  }],
  userCaseReviewDate: [{
    required: true, message: '请选择用例评审时间', trigger: 'change'
  }],
  presentationDate: [{
    required: true, message: '请选择提测时间', trigger: 'change'
  }],
  testDate: [{
    required: true, message: '请选择测试时间', trigger: 'change'
  }],
  onlineDate: [{
    required: true, message: '请选择上线日期', trigger: 'change'
  }]
}
// 版本创建时 规则
export const versionCreateRule = {
  changeType: [{
    required: true, message: '请选择变更原因类型', trigger: 'change'
  }],
  versionName: [{
    required: true, message: '请输入版本号', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本号'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本号不能包含特殊字符'),
    trigger: 'change'
  }],
  versionDesc: [{
    required: true, message: '请输入版本描述', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本描述'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本描述不能包含特殊字符'),
    trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选产品', trigger: 'change'
  }],
  type: [{
    required: true, message: '请选择版本类型', trigger: 'change'
  }],
  testerId: [{
    required: true, message: '请输入测试负责人', trigger: 'change'
  }]
}
// 确认版本计划时 规则
export const versionRuleNormal = {
  changeType: [{
    required: true, message: '请选择变更原因类型', trigger: 'change'
  }],
  versionName: [{
    required: true, message: '请输入版本号', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本号'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本号不能包含特殊字符'),
    trigger: 'change'
  }],
  versionDesc: [{
    required: true, message: '请输入版本描述', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本描述'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本描述不能包含特殊字符'),
    trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选产品', trigger: 'change'
  }],
  type: [{
    required: true, message: '请选择版本类型', trigger: 'change'
  }],
  testerId: [{
    required: true, message: '请输入测试负责人', trigger: 'change'
  }],
  demandReviewDate: [{
    required: true, message: '请选择需求评审日期', trigger: 'change'
  }],
  technicianReviewDate: [{
    required: false, message: '请选择技术评审时间', trigger: 'change'
  }],
  developDate: [{
    required: true, message: '请选择开发时间', trigger: 'change'
  }],
  userCaseReviewDate: [{
    required: true, message: '请选择用例评审时间', trigger: 'change'
  }],
  presentationDate: [{
    required: true, message: '请选择提测时间', trigger: 'change'
  }],
  testDate: [{
    required: true, message: '请选择测试时间', trigger: 'change'
  }],
  onlineDate: [{
    required: true, message: '请选择上线日期', trigger: 'change'
  }],
  applyId: [{
    required: true, message: '请选择变更申请人', trigger: 'change'
  }]
}
// 紧急版本规则
export const versionRuleQuick = {
  changeType: [{
    required: true, message: '请选择变更原因类型', trigger: 'change'
  }],
  versionName: [{
    required: true, message: '请输入版本号', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本号'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本号不能包含特殊字符'),
    trigger: 'change'
  }],
  versionDesc: [{
    required: true, message: '请输入版本描述', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本描述'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本描述不能包含特殊字符'),
    trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选产品', trigger: 'change'
  }],
  type: [{
    required: true, message: '请选择版本类型', trigger: 'change'
  }],
  testerId: [{
    required: true, message: '请输入测试负责人', trigger: 'change'
  }],
  demandReviewDate: [{
    required: true, message: '请选择需求评审日期', trigger: 'change'
  }],
  technicianReviewDate: [{
    required: false, message: '请选择技术评审时间', trigger: 'change'
  }],
  developDate: [{
    required: true, message: '请选择开发时间', trigger: 'change'
  }],
  userCaseReviewDate: [{
    required: false, message: '请选择用例评审时间', trigger: 'change'
  }],
  presentationDate: [{
    required: true, message: '请选择提测时间', trigger: 'change'
  }],
  testDate: [{
    required: true, message: '请选择测试时间', trigger: 'change'
  }],
  onlineDate: [{
    required: true, message: '请选择上线日期', trigger: 'change'
  }],
  createReason: [{
    required: true, message: '请选择紧急版本原因', trigger: 'change'
  }],
  reasonDesc: [{
    required: true, message: '请输入其他原因', trigger: 'change'
  }],
  applyId: [{
    required: true, message: '请选择变更申请人', trigger: 'change'
  }]
}
// 外采版本规则
export const versionRuleExternal = {
  changeType: [{
    required: true, message: '请选择变更原因类型', trigger: 'change'
  }],
  versionName: [{
    required: true, message: '请输入版本号', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本号'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本号不能包含特殊字符'),
    trigger: 'change'
  }],
  versionDesc: [{
    required: true, message: '请输入版本描述', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入版本描述'),
    trigger: 'change'
  }, {
    validator: validateNoFourBytesChar('版本描述不能包含特殊字符'),
    trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选产品', trigger: 'change'
  }],
  type: [{
    required: true, message: '请选择版本类型', trigger: 'change'
  }],
  demandReviewDate: [{
    required: true, message: '请选择需求评审日期', trigger: 'change'
  }],
  onlineDate: [{
    required: true, message: '请选择上线日期', trigger: 'change'
  }],
  applyId: [{
    required: true, message: '请选择变更申请人', trigger: 'change'
  }]
}

export const changeStoryVersionRule = {
  versionId: [{
    required: true, message: '请选择版本号', trigger: 'change'
  }]
}

export const productRule = {
  productName: [{
    required: true, message: '请输入产品线名称', trigger: 'change'
  }],
  productDesc: [{
    required: true, message: '请输入产品描述', trigger: 'change'
  }],
  productLineId: [{
    required: true, message: '请选择产品线', trigger: 'change'
  }],
  productType: [{
    required: true, message: '请选择产品类别', trigger: 'change'
  }],
  jiraProjectId: [{
    required: true, message: '请选择jira项目', trigger: 'change'
  }],
  producterId: [{
    required: true, message: '请选择产品负责人', trigger: 'change'
  }],
  developerId: [{
    required: true, message: '请选择开发负责人', trigger: 'change'
  }],
  projectManagerId: [{
    required: true, message: '请选择项目经理', trigger: 'change'
  }],
  testerId: [{
    required: true, message: '请选择测试负责人', trigger: 'change'
  }]
}

export const costShareRule = {
  department: [{
    validator(rule, value, callback) {
      if (!value) {
        callback(new Error('请选择部门'))
      } else {
        callback()
      }
    },
    trigger: 'blur'
  }],
  costRate: [{
    validator(rule, value, callback) {
      if (value || value === 0) {
        callback()
      } else {
        callback(new Error('请填写分摊比例'))
      }
    },
    trigger: 'change'
  }],
  total: [{
    validator(rule, value, callback) {
      if (value !== 100) {
        callback(new Error('分摊比例总和需为100%'))
      } else {
        callback()
      }
    }
  }]
}

export const handleFeedbackRule = {
  remark: [{
    required: true, message: '请输入处理意见', trigger: 'change'
  }]
}

export const rejectProjectApprovalRule = {
  message: [{
    required: true, message: '请输入不通过意见', trigger: 'change'
  }, {
    validator: validateNotAllEmptyChar('请输入不通过意见'),
    trigger: 'change'
  }]
}

export const closeProjectChangeRule = {
  message: [{
    required: true, message: '请输入变更原因', trigger: 'change'
  }]
}

export const projectFinishRule = {
  url: [{
    required: true, message: '请上传结项材料', trigger: 'change'
  }]
}

export const projectReplayRule = {
  url: [{
    required: true, message: '请上传复盘材料', trigger: 'change'
  }],
  actualValue: [{
    required: false, message: '请输入实际价值', trigger: 'change'
  }]
}
export const projectRiskRule = {
  riskLayer: [{
    required: true, message: '请选择风险等级', trigger: 'change'
  }],
  distinguishDate: [{
    required: true, message: '请选择识别时间', trigger: 'change'
  }],
  riskContent: [{
    required: true, message: '请输入识别内容', trigger: 'change'
  }],
  distinguishManId: [{
    required: true, message: '请输入识别人', trigger: 'change'
  }],
  riskOwnerId: [{
    required: true, message: '请输入责任人', trigger: 'change'
  }],
  riskScenarios: [{
    required: true, message: '请输入风险预案', trigger: 'change'
  }]
}
export const publishVersionRule = {
  jiraVersionId: [{
    required: true, message: '请选择jira版本', trigger: 'change'
  }],
  testDate: [{
    required: true, message: '请选择测试日期', trigger: 'change'
  }],
  onlineTime: [{
    required: true, message: '请选择上线日期', trigger: 'change'
  }]
}

export const publishPMSVersionRule = {
  productLineId: [{
    required: true, message: '请选择jira版本', trigger: 'change'
  }],
  productId: [{
    required: true, message: '请选择jira版本', trigger: 'change'
  }],
  versionId: [{
    required: true, message: '请选择jira版本', trigger: 'change'
  }],
  testDate: [{
    required: true, message: '请选择测试准入日期', trigger: 'change'
  }],
  testAllowExitActualTime: [{
    required: true, message: '请选择测试准出日期', trigger: 'change'
  }],
  onlineTime: [{
    required: true, message: '请选择上线日期', trigger: 'change'
  }],
  remark: [{
    required: true, message: '请输入手动上线原因', trigger: 'change'
  }]
}

export const projectChangeRule = {
  reason: [{
    required: true, message: '请选择变更类型', trigger: 'change'
  }],
  desc: [{
    required: true, message: '请输入变更描述', trigger: 'change'
  }],
  effect: [{
    required: true, message: '请输入变更影响', trigger: 'change'
  }],
  requirements: [
    {
      required: true, message: '项目至少关联一个需求', trigger: 'change'
    }
  ],
  stageName: [{
    required: true, message: '请输入里程碑名称', trigger: 'change'
  }],
  stageDate: [{
    required: true, message: '请选择计划时间', trigger: 'change'
  }],
  applyId: [{
    required: true, message: '请选择变更申请人', trigger: 'change'
  }]
}
export const versionChangeRule = {
  changeType: [{
    required: true, message: '请选择变更类型', trigger: 'change'
  }],
  demandReviewDate: [{
    required: true, message: '请选择需求评审时间', trigger: 'change'
  }],
  technicianReviewDate: [{
    required: true, message: '请选择技术评审时间', trigger: 'change'
  }],
  developDate: [{
    required: true, message: '请选择开发时间', trigger: 'change'
  }],
  userCaseReviewDate: [{
    required: true, message: '请选择用例评审时间', trigger: 'change'
  }],
  presentationDate: [{
    required: true, message: '请选择提测时间', trigger: 'change'
  }],
  testDate: [{
    required: true, message: '请选择测试时间', trigger: 'change'
  }],
  onlineDate: [{
    required: true, message: '请选择上线时间', trigger: 'change'
  }],
  changeDesc: [{
    required: true, message: '请输入变更描述', trigger: 'change'
  }],
  changeImpact: [{
    required: true, message: '请输入变更影响', trigger: 'change'
  }]
}

export const projectEmailRule = {
  projectId: [{
    required: true, message: '请选择项目名称', trigger: 'change'
  }],
  progress: [{
    required: true, message: '请填写当前进度', trigger: 'change'
  }, {
    validator(rule, value, callback) {
      if (value === '0') {
        callback(new Error('进度不能为0'))
      } else {
        callback()
      }
    },
    trigger: 'blur'
  }],
  progressDesc: [{
    required: true, message: '请填写进度描述', trigger: 'change'
  }],
  reportTime: [{
    required: true, message: '请完善汇报时间', trigger: 'change'
  }],
  taskTypeDesc: [{
    required: true, message: '任务类型不能为空', trigger: 'change'
  }],
  completeStatus: [
    {
      required: true, message: '完成情况不能为空', trigger: 'change'
    }
  ],
  summaryStatus: [
    {
      required: true, message: '任务状态不能为空', trigger: 'change'
    }
  ],
  gmtComplete: [
    {
      required: true, message: '实际完成时间不能为空', trigger: 'change'
    }
  ],
  taskDesc: [
    {
      required: true, message: '任务描述不能为空', trigger: 'change'
    }
  ],
  dutyerName: [
    {
      required: true, message: '负责人不能为空', trigger: 'change'
    }
  ],
  planStartDate: [
    {
      required: true, message: '计划开始时间不能为空', trigger: 'change'
    }
  ],
  planEndDate: [
    {
      required: true, message: '计划结束时间不能为空', trigger: 'change'
    }
  ],
  email: [{
    required: true, message: '请输入邮件地址', trigger: 'change'
  }, {
    validator(rule, value, callback) {
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/
      if (!reg.test(value)) {
        callback(new Error('请输入合法的邮箱'))
      } else {
        callback()
      }
    },
    trigger: 'blur'
  }]
}

export const transferRule = {
  riskOwnerId: [{
    required: true, message: '请选择流转人', trigger: 'change'
  }]
}
