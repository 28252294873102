import { getExecute } from '@/utils/dialogExecute'

export const rules = {
  name: [
    { required: true, message: '请输入供应商名称', trigger: 'change' }
  ],
  orderNo: [
    { required: true, message: '请输入序号', trigger: 'change' }
  ],
  type: [
    { required: true, message: '请选择供应商类型', trigger: 'change' }
  ],
  cover: [
    { required: true, message: '请选择封面', trigger: 'change' }
  ],
  tag: [
    { required: true, message: '请供应商标签', trigger: 'change' }
  ],
  location: [
    { required: true, message: '请供应商标签', trigger: 'change' }
  ],
  style: [
    { required: true, message: '请输入供应商风格', trigger: 'change' }
  ],
  description: [
    { required: true, message: '请输入供应商简介', trigger: 'change' }
  ]
}
export const SUPPLIER_TYPE = [
  {
    label: '场景服务',
    key: 'base'
  },
  {
    label: '服装',
    key: 'clothing'
  },
  {
    label: '道具',
    key: 'prop'
  },
  {
    label: '演员服务',
    key: 'actor'
  },
  {
    label: '住宿',
    key: 'hotel'
  },
  {
    label: '交通',
    key: 'traffic'
  },
  {
    label: '餐饮',
    key: 'food'
  }
]

const singleVue = {
  addOrEditSupplier: false,
  showImg: false
}

const strategyMap = {
  addOrEditSupplier: () => import('./components/addOrEditSupplier.vue'), // 日志显示
  showImg: () => import('./components/showImg.vue') // 日志显示
}

export const execute = getExecute(singleVue, strategyMap)
