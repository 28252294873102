<template>
  <div class="zto-tabs"
       id="z-tabs">
    <z-tabs :panes="tabs"
            v-model="selectTagName"
            type="card"
            @close-other="closeOther"
            @close-all="closeAll"
            @tab-remove="removeTab"
            @tab-click="clickTab"
            :class="{'zto-tabs-shadow': shadowStatus}">
    </z-tabs>
  </div>
</template>

<script>
import zTabs from '../../components/tabs/ZtoTabs.vue'
import { mapActions, mapGetters } from 'vuex'
// 由于elementUI 根据name来动态切换，在实际开发中考虑到name可能重合，新增key 表唯一（存在key转name的情况）
// tabs 的name 唯一有且只有一个！！！
// 在elemnt组件基础上新增加"关闭其他标签"，"关闭所有标签"可以被父元素监听！去修改store中的tabs
export default {
  name: 'ztoTabsHeader',
  components: { zTabs },
  props: {
    shadowStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slectedTag: {}, // 已选中的标签// 为了方便匹配selectTagName
      selectTagName: ''
    }
  },
  computed: {
    ...mapGetters({
      visitedTabs: 'getVisitedViews', // 所有的tabs页面
      cacheTabs: 'getCacheViews', // 有缓存的tabs页面
      defaultTabs: 'getDefaultViews' // 默认标签为首页
    }),
    tabs: function() {
      return [...this.defaultTabs, ...this.visitedTabs]
    }
  },
  mounted() {
    this.addViewTags()
    this.slectedTag = this.$route
    this.selectTagName = this.$route.name ? this.$route.name : this.$route.meta.linkName
  },
  methods: {
    ...mapActions({
      addVisitedTab: 'addVisitedView', // 将 `this.addVisitedTab()` 映射为 `this.$store.dispatch('addVisitedViews')`
      delVisitedTab: 'delVisitedView',
      delOthersTabs: 'delOthersViews',
      delAllTabs: 'delAllViews'
    }),
    // 关闭其他标签
    closeOther(tab) {
      this.delOthersTabs(tab).then(views => {
        this.$router.push({ name: tab.name, params: tab.params })
      })
    },
    // 关闭所有标签
    closeAll(tab) {
      this.delAllTabs().then(views => {
        this.$router.push('/')
      })
    },
    // 关闭tab
    removeTab(tab) {
      // 删除匹配规则见状态中的mutation方法
      this.delVisitedTab(tab).then(views => {
        if (views.length === 0) {
          this.$router.push('/')
        } else {
          const latestView = views.slice(-1)[0]
          this.$router.push({ name: latestView.name, params: latestView.params })
          this.selectTagName = latestView.name
        }
      })
    },
    // 选中tab
    clickTab(tab) {
      this.$router.push({ name: tab.name, params: tab.params })
    },
    generateRoute() {
      if (this.$route.name) {
        return this.$route
      }
      return false
    },
    addViewTags() {
      const route = this.generateRoute()
      if (!route) {
        return false
      }
      this.addVisitedTab(route)
    }
  },
  watch: {
    $route() {
      this.addViewTags()
      this.slectedTag = this.$route
      this.selectTagName = this.$route.name ? this.$route.name : this.$route.meta.linkName
    }
  }
}
</script>
