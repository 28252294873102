import httpService from '@/api/httpService'
import apiUrl from '@/api/apiUrl'

const state = {}
const getters = {}
const mutations = {}

const actions = {
  // 获取园区列表
  async getParkList({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.pagePark,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 新增园区
  async savePark({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.savePark,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 更新园区
  async updatePark({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.updatePark,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 删除园区
  async deletePark({ commit, state }, params) {
    const url = `${apiUrl.deletePark.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.deletePark,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 根据id获取园区详情
  async getParkById({ commit, state }, params) {
    const url = `${apiUrl.getParkById.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.getParkById,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 获取园区动态列表
  async getParkNewsList({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.pageParkNews,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 新增园区动态
  async saveParkNews({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.saveParkNews,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 更新园区动态
  async updateParkNews({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.updateParkNews,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 删除园区动态
  async deleteParkNews({ commit, state }, params) {
    const url = `${apiUrl.deleteParkNews.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.deleteParkNews,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 根据id获取园区动态详情
  async getParkNewsById({ commit, state }, params) {
    const url = `${apiUrl.getParkNewsById.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.getParkNewsById,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  }
}
export default {
  state,
  actions,
  mutations,
  getters
}
