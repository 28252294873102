import Vue from 'vue'
import { specialDate } from '../constants/constants'
import { PRODUCT_TYPE } from '@/views/productManage/option'
import { SUPPLIER_TYPE } from '@/views/supplierManage/option'
import { util } from './util'
import moment from 'moment'

const key2Label = util.propsTransGenerator('key', 'label')
const key2Name = util.propsTransGenerator('key', 'name')

Vue.filter('policyType', input => {
  if (input === 'news') {
    return '资讯'
  } else {
    return '指南'
  }
})

Vue.filter('supplierType', input => {
  if (input === 'base') {
    return '影视基地'
  } else {
    return '租赁公司'
  }
})

Vue.filter('caseStatus', input => {
  const statusMap = {
    NEW: '新增',
    SUCCESS: '审核通过',
    FAIL: '审核失败'
  }
  return statusMap[input]
})

Vue.filter('productType2Text', input => {
  return key2Label(PRODUCT_TYPE, input)
})

Vue.filter('supplierType2Text', input => {
  return key2Label(SUPPLIER_TYPE, input)
})

Vue.filter('dateStrDay', input => {
  if (!input && input !== 0) return
  return input.split(' ')[0]
})

Vue.filter('toDateString', (input, flag) => {
  if (!input) {
    return ''
  }
  return util.dateToStr(new Date(input), flag)
})
Vue.filter('date', (input, format) => {
  return moment(input).format(format)
})

Vue.filter('limitLength', (input, length) => {
  return util.limitLength(input, length)
})
Vue.filter('encodeURI', input => {
  const reg = /(https?:\/\/)?(.*)/
  return input.replace(reg, (_, protocal, other) => {
    return protocal + encodeURIComponent(other)
  })
})
Vue.filter('specialDate', input => {
  const date = new Date(input).setHours(0, 0, 0, 0)
  const todayDate = new Date()
  const oneDayMs = 24 * 60 * 60 * 1000
  todayDate.setHours(0, 0, 0, 0)
  // const thisYear = todayDate.getFullYear()
  const today = todayDate.getTime()
  const yesterday = today - oneDayMs
  const theDayBeforeYesterday = yesterday - oneDayMs
  const index = [today, yesterday, theDayBeforeYesterday].findIndex(item => {
    return item === date
  })
  if (index > -1) {
    return specialDate[index]
  } else {
    return util.dateToStr(new Date(input), 1)
  }
})
