<template>
  <!--iframe页面展示区域-->
  <div id="frameBox">
    <el-tabs :value="selectTab" class="full" type="card">
      <el-tab-pane v-for="item in frameTabData" :key="item.name" :label="item.name" :name="item.name">
        <iframe :src="item.url" :key="item.name" :name="item.name" frameborder="0" v-if="frameViews.indexOf(item.name)>-1"></iframe>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'frameView',
  props: ['selectTab', 'iframeUrl'],
  computed: {
    ...mapState({
      frameViews: state => state.frame.frameViews
    })
  },
  data() {
    return {
      tabUrl: this.selectTab + this.iframeUrl,
      frameTabData: [
        {
          name: 'vision', // 与to.meta.iframe保持一致
          domain: 'http://ued.dev.ztosys.com/build/iframe', // 主域名
          url: 'http://ued.dev.ztosys.com/build/iframe', // 主域名拼接路径
          mode: '/#' // iframe引入项目的路由模式。hash路由 '/#'  brower路由 ''
        },
        {
          name: 'cainiao',
          domain: 'http://www.runoob.com',
          url: 'http://www.runoob.com/',
          mode: ''
        },
        {
          name: 'vue',
          domain: 'https://cn.vuejs.org',
          url: 'https://cn.vuejs.org',
          mode: ''
        }
      ]
    }
  },
  watch: {
    selectTab() {
      this.tabUrl = this.selectTab + this.iframeUrl
    },
    iframeUrl() {
      this.tabUrl = this.selectTab + this.iframeUrl
    },
    tabUrl() {
      this.iframeJump()
    }
  },
  methods: {
    iframeJump() {
      let domain = ''
      for (var v of this.frameTabData) {
        if (this.selectTab === v.name) {
          domain = v.domain
          if (!window.frames[this.selectTab]) {
            v.url = v.domain + v.mode + this.iframeUrl
          }
        }
      }
      if (window.frames[this.selectTab]) window.frames[this.selectTab].postMessage(this.iframeUrl, domain)
    }
  },
  created() {
    this.iframeJump()
  }
}
</script>
<style lang="scss" scope>