/**
 * @author chengxuan
 * @description api url setting
 */
import env from '@/config/env.js'
import GLOBAL from '@/config/global' // 全局变量

// 开启本地mock的话，不使用HostName这类接口域名
const HostName = GLOBAL.mockLocal ? '' : env.HostName
// const UploadHostName = process.env.NODE_ENV === 'production' ? 'https://fs.zto.com' : 'http://fs.test.ztosys.com'
const UploadHostName = env.UploadHostName
const SSOHostName = env.SSOHostName
// const UploadHostName = 'http://fs.test.ztosys.com'

const apiUrl = {
  hostName: HostName,
  // 后端自定义获取用户信息
  login: {
    url: HostName + '/login',
    method: 'POST'
  },
  // 后端自定义获取用户信息
  getUserInfo: {
    url: HostName + '/back/login',
    method: 'GET'
  },
  // 资讯模块
  // 新增资讯
  savePolicy: {
    url: `${HostName}/policy/save`,
    method: 'POST'
  },
  // 更新资讯
  updatePolicy: {
    url: `${HostName}/policy/update`,
    method: 'PUT'
  },
  // 删除资讯
  deletePolicy: {
    url: `${HostName}/policy/delete`,
    method: 'DELETE'
  },
  // 根据资讯id 获取资讯详情
  getPolicyById: {
    url: `${HostName}/policy/detail`,
    method: 'GET'
  },
  // 分页查询资讯列表
  pagePolicy: {
    url: `${HostName}/policy/page`,
    method: 'POST'
  },
  // 供应商模块
  // 新增资讯
  saveSupplier: {
    url: `${HostName}/supplier/save`,
    method: 'POST'
  },
  // 更新资讯
  updateSupplier: {
    url: `${HostName}/supplier/update`,
    method: 'PUT'
  },
  // 删除资讯
  deleteSupplier: {
    url: `${HostName}/supplier/delete`,
    method: 'DELETE'
  },
  // 根据资讯id 获取资讯详情
  getSupplierById: {
    url: `${HostName}/supplier/detail`,
    method: 'GET'
  },
  // 分页查询资讯列表
  pageSupplier: {
    url: `${HostName}/supplier/page`,
    method: 'POST'
  },
  // 剧组备案模块
  // 新增资讯
  saveCase: {
    url: `${HostName}/case/save`,
    method: 'POST'
  },
  // 更新资讯
  updateCase: {
    url: `${HostName}/case/update`,
    method: 'PUT'
  },
  // 删除资讯
  deleteCase: {
    url: `${HostName}/case/delete`,
    method: 'DELETE'
  },
  // 根据资讯id 获取资讯详情
  getCaseById: {
    url: `${HostName}/case/detail`,
    method: 'GET'
  },
  // 分页查询资讯列表
  pageCase: {
    url: `${HostName}/case/page`,
    method: 'POST'
  },
  // 商品管理模块
  // 新增商品
  saveProduct: {
    url: `${HostName}/product/save`,
    method: 'POST'
  },
  // 更新商品
  updateProduct: {
    url: `${HostName}/product/update`,
    method: 'PUT'
  },
  // 删除商品
  deleteProduct: {
    url: `${HostName}/product/delete`,
    method: 'DELETE'
  },
  // 根据商品id 获取商品详情
  getProductById: {
    url: `${HostName}/product/detail`,
    method: 'GET'
  },
  // 分页查询商品列表
  pageProduct: {
    url: `${HostName}/product/page`,
    method: 'POST'
  },
  // 商品推荐模块
  // 新增商品推荐
  saveProductRecommend: {
    url: `${HostName}/productRecommend/save`,
    method: 'POST'
  },
  // 更新商品推荐
  updateProductRecommend: {
    url: `${HostName}/productRecommend/update`,
    method: 'PUT'
  },
  // 删除商品推荐
  deleteProductRecommend: {
    url: `${HostName}/productRecommend/delete`,
    method: 'DELETE'
  },
  // 根据商品推荐id 获取商品推荐详情
  getProductRecommendById: {
    url: `${HostName}/productRecommend/detail`,
    method: 'GET'
  },
  // 分页查询商品推荐列表
  pageProductRecommend: {
    url: `${HostName}/productRecommend/page`,
    method: 'POST'
  },
  // 商品细节模块
  // 新增商品推荐
  saveProductDetail: {
    url: `${HostName}/productDetail/save`,
    method: 'POST'
  },
  // 更新商品推荐
  updateProductDetail: {
    url: `${HostName}/productDetail/update`,
    method: 'PUT'
  },
  // 删除商品推荐
  deleteProductDetail: {
    url: `${HostName}/productDetail/delete`,
    method: 'DELETE'
  },
  // 根据商品推荐id 获取商品推荐详情
  getProductDetailById: {
    url: `${HostName}/productDetail/detail`,
    method: 'GET'
  },
  // 分页查询商品推荐列表
  pageProductDetail: {
    url: `${HostName}/productDetail/page`,
    method: 'POST'
  },
  // 商品过往模块
  // 新增商品过往
  saveProductUsage: {
    url: `${HostName}/productUsage/save`,
    method: 'POST'
  },
  // 更新商品过往
  updateProductUsage: {
    url: `${HostName}/productUsage/update`,
    method: 'PUT'
  },
  // 删除商品过往
  deleteProductUsage: {
    url: `${HostName}/productUsage/delete`,
    method: 'DELETE'
  },
  // 根据商品过往id 获取商品过往详情
  getProductUsageById: {
    url: `${HostName}/productUsage/detail`,
    method: 'GET'
  },
  // 分页查询商品过往列表
  pageProductUsage: {
    url: `${HostName}/productUsage/page`,
    method: 'POST'
  },
  // 重点企业模块
  // 新增重点企业
  saveCompany: {
    url: `${HostName}/company/save`,
    method: 'POST'
  },
  // 更新重点企业
  updateCompany: {
    url: `${HostName}/company/update`,
    method: 'PUT'
  },
  // 删除重点企业
  deleteCompany: {
    url: `${HostName}/company/delete`,
    method: 'DELETE'
  },
  // 根据重点企业id 获取重点企业详情
  getCompanyById: {
    url: `${HostName}/company/detail`,
    method: 'GET'
  },
  // 分页查询重点企业列表
  pageCompany: {
    url: `${HostName}/company/page`,
    method: 'POST'
  },
  // 扶持记录模块
  // 新增扶持记录
  saveSupportRecord: {
    url: `${HostName}/supportRecord/save`,
    method: 'POST'
  },
  // 更新扶持记录
  updateSupportRecord: {
    url: `${HostName}/supportRecord/update`,
    method: 'PUT'
  },
  // 删除扶持记录
  deleteSupportRecord: {
    url: `${HostName}/supportRecord/delete`,
    method: 'DELETE'
  },
  // 根据扶持记录id 获取扶持记录详情
  getSupportRecordById: {
    url: `${HostName}/supportRecord/detail`,
    method: 'GET'
  },
  // 分页查询扶持记录列表
  pageSupportRecord: {
    url: `${HostName}/supportRecord/page`,
    method: 'POST'
  },
  // 园区模块
  // 新增园区
  savePark: {
    url: `${HostName}/park/save`,
    method: 'POST'
  },
  // 新增园区动态
  saveParkNews: {
    url: `${HostName}/park/news/save`,
    method: 'POST'
  },
  // 更新园区
  updatePark: {
    url: `${HostName}/park/update`,
    method: 'PUT'
  },
  // 更新园区动态
  updateParkNews: {
    url: `${HostName}/park/news/update`,
    method: 'PUT'
  },
  // 删除园区
  deletePark: {
    url: `${HostName}/park/delete`,
    method: 'DELETE'
  },
  // 删除园区动态详情
  deleteParkNews: {
    url: `${HostName}/park/news/delete`,
    method: 'DELETE'
  },
  // 根据园区id 获取园区
  getParkById: {
    url: `${HostName}/park/detail`,
    method: 'GET'
  },
  // 根据园区id 获取园区 动态详情
  getParkNewsById: {
    url: `${HostName}/park/news/detail`,
    method: 'GET'
  },
  // 获取园区 前N条
  getParkTop: {
    url: `${HostName}/park/top`,
    method: 'GET'
  },
  // 分页查询园区
  pagePark: {
    url: `${HostName}/park/page`,
    method: 'POST'
  },
  // 分页查询园区动态
  pageParkNews: {
    url: `${HostName}/park/news/page`,
    method: 'POST'
  },
  // 后端自定义清除后端 session
  clearSysSession: {
    url: HostName + '/logout',
    method: 'GET'
  },
  // 清除sso session
  clearZtoSession: {
    url: SSOHostName,
    method: 'DELETE'
  },
  getAccessST: {
    url: `${UploadHostName}/GetSessionST`,
    method: 'GET'
  },
  // 获取上传文件签名
  getSignature: {
    url: `${HostName}/third/fileSecurityService/getSignature`,
    method: 'POST'
  }
}
export default apiUrl
