import httpService from '@/api/httpService'
import apiUrl from '@/api/apiUrl'

const state = {}
const getters = {}
const mutations = {}

const actions = {
  // 获取供应商列表
  async getCompanyList({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.pageCompany,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 新增供应商
  async saveCompany({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.saveCompany,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 更新供应商
  async updateCompany({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.updateCompany,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 删除供应商
  async deleteCompany({ commit, state }, params) {
    const url = `${apiUrl.deleteCompany.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.deleteCompany,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 根据id获取供应商详情
  async getCompanyById({ commit, state }, params) {
    const url = `${apiUrl.getCompanyById.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.getCompanyById,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  }
}
export default {
  state,
  actions,
  mutations,
  getters
}
