/**
 *
 * 菜单，路由工具
 */
import Vue from 'vue'
import { util } from '@/utils/util'
import Layout from '@/modules/layout/Layout'
import Nest2 from '@/modules/layout/Nest2'
import Nest3 from '@/modules/layout/Nest3'
import Nest4 from '@/modules/layout/Nest4'
import codeMap from './config'
export const tool = {
  // 过滤菜单参数
  filterMenu(items) {
    for (var v of items) {
      v.name = v.code
      v.isItem = true
      v.nav = 'default'
      v.hidden = false
      if (codeMap[v.code] && codeMap[v.code].nav) {
        v.nav = codeMap[v.code].nav
      }
      if (codeMap[v.code] && codeMap[v.code].hidden) {
        v.hidden = codeMap[v.code].hidden
      }
      if (v.children.length > 0) {
        v.isItem = false
        this.filterMenu(v.children)
      }
    }
  },
  // 有效的路由index数组
  effectIndex(menuDeep, routeDeep) {
    var arr = []
    for (var i = 1; i <= menuDeep; i++) {
      if (i === 1) {
        arr.push(routeDeep)
      } else if (i === 2) {
        arr.push(1)
      } else {
        arr.push(i - 1)
      }
    }
    return arr
  },
  // 非引用传递,深层级数
  getFloor(treeData) {
    const floor = 0
    let max = 0
    function each(data, floor) {
      data.forEach(e => {
        e.floor = floor
        if (floor > max) {
          max = floor
        }
        if (e.children && e.children.length > 0) {
          each(e.children, floor + 1)
        }
      })
    }
    each(util.deepCopy(treeData), floor + 1)
    return max
  },
  // 引用传递,深层级数
  getFloorRefer(treeData) {
    const floor = 0
    let max = 0
    function each(data, floor) {
      data.forEach(e => {
        e.floor = floor
        if (floor > max) {
          max = floor
        }
        if (e.children && e.children.length > 0) {
          each(e.children, floor + 1)
        }
      })
    }
    each(treeData, floor + 1)
    return max
  },
  // 构建路由
  buildRoutes(item, obj, index, menuDeep, routeDeep) {
    var nameArr = this.effectIndex(menuDeep, routeDeep)
    if (nameArr.includes(index)) {
      obj.path = index === 1 ? '/' : ''
      obj.path += item.code.includes('_') ? item.code.replace('_', '/:') : item.code
      obj.name = item.code
      obj.icon = item.menuIcon
      const com = codeMap[item.code]
      const cache = com && com.cache !== undefined ? com.cache : true
      const closable = com && com.closable !== undefined ? com.closable : true
      const iframe = com && com.iframe !== undefined ? com.iframe : ''
      const iframeUrl = com && com.iframeUrl !== undefined ? com.iframeUrl : ''

      obj.meta = {
        label: item.menuName,
        cache: cache,
        closable: closable,
        iframe: iframe,
        iframeUrl: iframeUrl,
        nav: item.nav
      }
      item.used = true
    } else {
      obj.path = ''
    }
    if (index === 1) {
      obj.component = Layout
      obj.redirect = 'noredirect'
    }
    if (index === 2) obj.component = item.used && item.menuUrl ? () => import(`@/views/${item.menuUrl}`) : Nest2
    if (index === 3) obj.component = item.used && item.menuUrl ? () => import(`@/views/${item.menuUrl}`) : Nest3
    if (index === 4) obj.component = item.used && item.menuUrl ? () => import(`@/views/${item.menuUrl}`) : Nest4
    obj.children = []
    if (index < routeDeep) {
      if (item.children.length > 0 && item.used) {
        for (var v of item.children) {
          var objSub = {}
          obj.children.push(this.buildRoutes(v, objSub, index + 1, v.floor + this.getFloor(v.children), routeDeep))
        }
      } else {
        var objCur = {}
        obj.children.push(
          this.buildRoutes(item, objCur, index + 1, item.floor + this.getFloor(item.children), routeDeep)
        )
      }
    }
    return obj
  },
  // 添加详情页路由
  pageDetail(params, obj, index, routeDeep) {
    obj.children = []
    if (index === routeDeep) {
      var Detail = params.component
      const Component = Vue.component(params.name, {
        render() {
          return <Detail />
        },
        components: { Detail }
      })
      obj.path = params.path
      obj.name = params.name
      obj.component = Component
      obj.meta = params.meta
    } else {
      obj.path = ''
      if (index === 1) obj.component = Layout
      if (index === 2) obj.component = Nest2
      if (index === 3) obj.component = Nest3
      if (index === 4) obj.component = Nest4
      var objSub = {}
      obj.children.push(this.pageDetail(params, objSub, index + 1, routeDeep))
    }
    return obj
  }
}
