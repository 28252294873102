<template>
  <div :class="'z-body z-skin-'+skin">
    <!--头部-->
    <z-new-header v-if="navOpen"></z-new-header>
    <z-header v-if="!navOpen"></z-header>
    <div class="z-container">
      <!--侧边菜单栏-->
      <z-new-menu @collapse="handCollapse"></z-new-menu>
      <div class="z-content"
           :style="{'margin-left':marginLeft}">
        <!--标签栏-->
        <z-tabs ref="zTabs"
                :shadow-status="shadowStatus"></z-tabs>
        <!--主要内容-->
        <z-main @scroll-main="scrollMain">
          <!--<z-footer></z-footer>-->
        </z-main>
      </div>
    </div>
  </div>
</template>

<script>
// import zFooter from './components/ZtoFooter'
import zNewHeader from './components/ZtoNewHeader'
import zHeader from './components/ZtoHeader'
import zNewMenu from './components/ZtoNewSideBar'
import zTabs from './components/ZtoTabsHeader'
import zMain from './components/ZtoMain'
import { mapGetters } from 'vuex'
export default {
  name: 'layout',
  data() {
    return {
      marginLeft: '180px',
      shadowStatus: false
    }
  },
  computed: {
    ...mapGetters({
      navOpen: 'navOpen',
      skin: 'skin'
    })
  },
  methods: {
    handCollapse(isCollapse) {
      if (isCollapse) {
        this.marginLeft = '40px'
      } else {
        this.marginLeft = '180px'
      }
    },
    scrollMain(e) {
      if (e.top > 0) {
        this.shadowStatus = true
      } else {
        this.shadowStatus = false
      }
    }
  },
  mounted() {
    // 关闭当前标签事件
    this.Event.$on('closeTab', () => {
      const tab = {
        name: this.$route.name,
        params: this.$route.params
      }
      this.$refs.zTabs.removeTab(tab)
    })
  },
  components: {
    // zFooter,
    zNewHeader,
    zHeader,
    zNewMenu,
    zTabs,
    zMain
  }
}
</script>

<style lang="scss" scoped>
.z-container {
  .z-content {
    transition: margin 0.2s linear 0.01s;
  }
}
</style>