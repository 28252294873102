const Types = {
  ADD_VISITED_VIEWS: 'ADD_VISITED_VIEWS',
  DEL_VISITED_VIEWS: 'DEL_VISITED_VIEWS',
  DEL_OTHERS_VIEWS: 'DEL_OTHERS_VIEWS',
  DEL_ALL_VIEWS: 'DEL_ALL_VIEWS'
}
const state = {
  visitedViews: [], // 所有正在游览的页面,不包括defaultViews
  cachedViews: [], // 需要做缓存的页面,不包括defaultViews
  // defaultViews 默认标签。使路由里的 closable,cache 失效。
  // defaultViews closable 固定为false 不可关闭。
  // 总浏览页面 visitedViews+defaultViews，总缓存页面cachedViews+defaultViews
  defaultViews: [
    // 数据格式
    /* {
      label: '首页', // 展示的标题
      name: 'welcome', // tab对应的router name
      closable: false, // 不可修改
      cache: true
    }*/
  ]
}
const getters = {
  getVisitedViews: state => {
    return state.visitedViews
  },
  getCacheViews: state => {
    const defaultArr = []
    for (const v of state.defaultViews) {
      if (v.cache) {
        defaultArr.push(v.name)
      }
    }
    return [...defaultArr, ...state.cachedViews]
  },
  getDefaultViews: state => {
    return state.defaultViews
  }
}
const actions = {
  addVisitedView({ commit }, view) {
    commit(Types.ADD_VISITED_VIEWS, view)
  },
  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit(Types.DEL_VISITED_VIEWS, view)
      resolve([...state.visitedViews])
    })
  },
  delOthersViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit(Types.DEL_OTHERS_VIEWS, view)
      resolve([...state.visitedViews])
    })
  },
  delAllViews({ commit, state }) {
    return new Promise(resolve => {
      commit(Types.DEL_ALL_VIEWS)
      resolve([...state.visitedViews])
    })
  }
}
const mutations = {
  ADD_VISITED_VIEWS: (state, view) => {
    // defaultViews不添加
    if (state.defaultViews.some(v => v.name === view.name)) {
      return
    }
    // 已存在于visitedViews的 如果是详情页，详情id也存在的。不添加
    for (const v of state.visitedViews) {
      if (v.name === view.name && v.params.id === view.params.id) {
        v.params = view.params
        return
      }
    }
    // 添加visitedViews
    state.visitedViews.push({
      params: view.params,
      name: view.name,
      label: view.params.id ? view.meta.label + '-' + view.params.id : view.meta.label,
      closable: view.meta.closable,
      cache: view.meta.cache
    })
    // 添加cachedViews
    if (view.meta.cache) {
      const cachedViews = [...state.cachedViews]
      cachedViews.push(view.name)
      state.cachedViews = Array.from(new Set([...cachedViews]))
    }
  },
  DEL_VISITED_VIEWS: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      // views.params.id id属性固定。非动态添加详情页的删除
      if (v.name === view.name && v.params.id === view.params.id) {
        state.visitedViews.splice(i, 1)
        break
      }
    }
    for (const i of state.cachedViews) {
      if (i === view.name) {
        const index = state.cachedViews.indexOf(i)
        state.cachedViews.splice(index, 1)
        break
      }
    }
  },
  DEL_OTHERS_VIEWS: (state, view) => {
    const visitedArr = []
    const cachedArr = []
    for (const v of state.visitedViews) {
      if (!v.closable || (v.name === view.name && v.params.id === view.params.id)) {
        visitedArr.push(v)
        if (v.cache) {
          cachedArr.push(v.name)
        }
      }
    }
    state.visitedViews = visitedArr
    state.cachedViews = cachedArr
  },
  DEL_ALL_VIEWS: state => {
    const visitedArr = []
    const cachedArr = []
    for (const v of state.visitedViews) {
      // 不可关闭的
      if (!v.closable) {
        visitedArr.push(v)
        if (v.cache) {
          cachedArr.push(v.name)
        }
      }
    }
    state.visitedViews = visitedArr
    state.cachedViews = cachedArr
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
