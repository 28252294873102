import Layout from '@/modules/layout/Layout.vue'
// 基础数据路由
export default {
  path: '/companyManage',
  name: 'companyManage',
  component: Layout,
  redirect: 'noredirect',
  icon: 'fa fa-envelope-o',
  meta: {
    label: '重点企业管理'
  },
  children: [
    {
      path: 'companyList',
      name: 'companyList',
      component: () => import('@/views/companyManage/companyList.vue'),
      meta: {
        label: '重点企业列表', // 标题
        closable: true
      }
    }
  ]
}
