import GLOBAL from '@/config/global'
const Types = {
  SET_VIEWTYPE: 'SET_VIEWTYPE',
  ADD_FRAME_VIEWS: 'ADD_FRAME_VIEWS'
}
const state = {
  viewType: true,
  frameViews: [] // frame容器name列表
}
const getters = {
  getViewType: state => {
    return state.viewType
  }
}
const mutations = {
  [Types.SET_VIEWTYPE](state, viewType) {
    state.viewType = viewType
  },
  [Types.ADD_FRAME_VIEWS](state, view) {
    if (state.frameViews.indexOf(view.meta.iframe) > -1) {
      state.frameViews.splice(state.frameViews.indexOf(view.meta.iframe), 1)
    }
    state.frameViews.push(view.meta.iframe)
    if (state.frameViews.length > GLOBAL.frameLen) {
      state.frameViews.splice(0, 1)
    }
  }
}
const actions = {
  catViewType({ commit }, viewType) {
    commit(Types.SET_VIEWTYPE, viewType)
  },
  addFrameViews({ commit }, view) {
    commit(Types.ADD_FRAME_VIEWS, view)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
