<template>
  <div class="zto-sidebar" :class="{'z-collapse-menu': isCollapse,
        'nav-open': navOpen}">
    <h2 class="zto-sidebar-header" v-if="!navOpen"><img class="logo-img" :src="logo" v-if="!isCollapse">
      <span v-else>
        <svg-icon icon-class="branch-1"></svg-icon>
      </span>
    </h2>
    <div class="zto-sidebar-body">
      <z-menu :menus="userInfo.menus[curNav]" :isCollapse="isCollapse" :defaultOpeneds="defaultOpeneds" :defaultActive="defaultActive" :uniqueOpened="uniqueOpened" @executeRouter="executeRouter"></z-menu>
    </div>
    <div class="zto-sidebar-footer" @click="toggleMenu">
      <svg-icon :icon-class="isCollapse?'arrowright-2':'arrowleft-2'"></svg-icon>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import logo from '@/assets/layout/logo.png'
import zMenu from '@/modules/components/menu/Menu'
export default {
  name: 'ztoNewSideBar',
  // 必须严格按照此格式，
  components: {
    'z-menu': zMenu
  },
  data() {
    return {
      uniqueOpened: false, // 是否允许只能打开一个菜单
      logo: logo, // 侧边栏展开状态下的logo
      isCollapse: false, // 侧边栏是否默认收起
      defaultActive: '', // 默认激活的导航
      defaultOpeneds: [] // 默认打开的菜单
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      curNav: 'curNav',
      navOpen: 'navOpen'
    })
  },
  methods: {
    toggleMenu() {
      this.isCollapse = !this.isCollapse
      this.$emit('collapse', this.isCollapse)
      this.Event.$emit('allCollapse', this.isCollapse)
    },
    /**
     * 跳转路由，通过事件代理的方式（全部路由 由tabs组件处理）
     * @param menu
     */
    executeRouter(menu) {
      // console.log(menu) // for debug
      // this.defaultActive = menu && menu.key // 更新当前激活的导航
      if (menu.isItem) {
        this.$router.push({ name: menu.name })
      }
    }
  },
  watch: {
    $route() {
      // console.log(this.$route.meta.key) 侧边栏的导航激活受routes中的数据影响
      this.defaultActive = this.$route.name
    }
  },
  mounted() {
    this.defaultActive = this.$route.name
  }
}
</script>


