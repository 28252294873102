// URL为域名、API为调的api接口路径。不同环境的域名用不同环境的接口
const env = {
  // 生产环境
  PROD_URL: ['http://www.cangchengwh.xyz'],
  // 开发环境
  DEV_URL: ['http://localhost']
}

// 后台接口的域名。如模板 HostName 为网关接口。
let HostName = 'back'
let logoutUrl = ''
let UploadHostName = ''
let SSOHostName = ''
let UploadAppId = ''
function checkUrl(url) {
  return window.location.href.indexOf(url) === 0
}
if (env.PROD_URL.length > 0 && env.PROD_URL.some(checkUrl)) {
  // 生产环境
  logoutUrl = env.PROD_URL[0]
} else {
  logoutUrl = env.DEV_URL[0]
  // 开发环境
//   UploadAppId = 'FSTEST'
//   HostName = env.DEV_API.HostName || ''
//   UploadHostName = env.DEV_UPLOAD.HostName || ''
//   SSOHostName = env.DEV_SSO_API.HostName || ''
}

export default {
  HostName,
  UploadHostName,
  SSOHostName,
  UploadAppId,
  logoutUrl
}
