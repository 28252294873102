import Layout from '@/modules/layout/Layout.vue'
import Nest2 from '@/modules/layout/Nest2'
// 基础数据路由
export default {
  path: '',
  component: Layout,
  redirect: 'noredirect',
  meta: {
    hidden: true,
    role: 'me_common'
  },
  children: [
    {
      path: '',
      component: Nest2,
      meta: { noMe: true, role: 'me_welcomeP' },
      children: [
        {
          path: '/welcome',
          name: 'welcome',
          component: () => import('@/views/common/Welcome'),
          meta: {
            label: '主页', // 标题
            cache: true, // 是否缓存
            closable: false, // 是否标签可关闭
            role: 'me_welcome'
          }
        }
      ]
    },
    {
      path: '',
      component: Nest2,
      meta: { noMe: true, role: 'me_welcomeP' },
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/common/Login'),
          meta: {
            label: '登录', // 标题
            cache: true, // 是否缓存
            closable: false, // 是否标签可关闭
            role: 'login'
          }
        }
      ]
    }
  ]
}
