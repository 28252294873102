<template>
  <section class="zto-mainbody" ref="appmain" :style="{width:pageWidth,height:pageHeight}" @scroll="mainScroll()">
    <transition name="fade" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view></router-view>
      </keep-alive>
    </transition>
    <frame-view v-show="!viewType" :selectTab="selectTab" :iframeUrl="iframeUrl"></frame-view>
  </section>
</template>
<script>
import FrameView from '@/modules/components/frame/FrameView.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'ztoMain',
  components: {
    FrameView
  },
  data() {
    return {
      pageWidth: 'auto',
      pageHeight: 'auto',
      selectTab: '',
      iframeUrl: '',
      redHeight: ''
    }
  },
  watch: {
    '$route'(to, from) {
      this.catView(to)
    },
    navOpen() {
      this.redHeight = this.navOpen ? 96 : 78
      this.pageHeight = document.documentElement.clientHeight - this.redHeight + 'px'
    }
  },
  computed: {
    ...mapState({
      viewType: state => state.frame.viewType
    }),
    ...mapGetters({
      navOpen: 'navOpen'
    }),
    cachedViews() {
      return this.$store.getters.getCacheViews
    }
  },
  created() {
    this.catView(this.$route)
    this.redHeight = this.navOpen ? 96 : 78
    this.pageHeight = document.documentElement.clientHeight - this.redHeight + 'px'
    window.addEventListener(
      'resize',
      () => {
        this.pageHeight = document.documentElement.clientHeight - this.redHeight + 'px'
      },
      false
    )
  },
  methods: {
    ...mapActions({
      catViewType: 'catViewType',
      addFrameViews: 'addFrameViews'
    }),
    catView(to) {
      if (to.meta.iframe) {
        this.selectTab = to.meta.iframe
        this.iframeUrl = to.meta.iframeUrl || ''
        this.catViewType(false)
        this.addFrameViews(to)
      } else {
        this.catViewType(true)
      }
    },
    mainScroll() {
      const _this = this
      this.$emit('scroll-main', {
        top: _this.$refs.appmain.scrollTop
      })
    }
  }
}
</script>
