import Vue from 'vue'
import store from '@/store/store'
import * as filters from '@/utils/filters'
import router from '@/router/routes'

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

function createVueComponent(vueOption) {
  const tempVueCom = new Vue({
    store,
    router,
    render: (h) => h(vueOption)
  }).$mount()

  document.body.append(tempVueCom.$el)
  return tempVueCom.$children[0]
}

export function getExecute(singleVue, strategyMap) {
  let currentVueCom = null
  return async function execute(command, params) {
    if (!singleVue[command]) {
      let tempOption = null
      if (typeof strategyMap[command] === 'function') {
        tempOption = (await strategyMap[command]()).default
      } else {
        tempOption = strategyMap[command]
      }
      singleVue[command] = createVueComponent(tempOption)
    }
    currentVueCom = singleVue[command]
    if (currentVueCom.show && typeof currentVueCom.show === 'function') {
      currentVueCom.show(params)
    } else {
      console.error('请提供一个show方法以便组件初始化')
    }
  }
}
