import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css' // 引入 element ui 样式
import 'normalize.css/normalize.css' // 解决游览器之间的差异
import 'font-awesome/css/font-awesome.css' // font-awesome图标
import '@/scss/index.scss' // 自定义样式
import '@/scss/icons/iconfont' // icon
import '@/scss/icons' // icon

import '@/modules/directive' // 自定义指令

import App from './App.vue'

import './permission'

import router from '@/router/routes'
import store from '@/store/store' // vuex状态
import '@/utils/filters' // 自定义过滤器
import '@/modules/directive' // 自定义指令
import Global from './config/global' // 全局变量

Vue.prototype.Event = new Vue()
Vue.config.productionTip = false

Vue.use(Element, {
  size: 'mini' // set element-ui default size
})
Object.defineProperty(Vue.prototype, 'GLOBAL', {
  value: Global
}) // 定义了全局 this.GLOBAL

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
