import router from '@/router/routes'
import store from '@/store/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({ showSpinner: false }) // NProgress Configuration
let firstLoad = true
router.beforeEach((to, from, next) => {
  NProgress.start() // 开启Progress
  if (store.getters.roleName && firstLoad) {
    firstLoad = false
    store.dispatch('reSetRouter').then(() => {
      console.log('reSetRouter')
    })
  }
  // 刷新时空路径往welcome跳转
  if (to.fullPath === '/' && to.path === '/') {
    if(store.getters.roleName === 'ROLE_SUPER_ADMIN'){
      next({ path: '/policyManage/policyList' })
    } else {
      next({ path: '/supplierManage/supplierList'})
    }
    return
  }
  // 判断是否有token
  // 判断当前用户是否已经有权限数据
  if (firstLoad && !store.getters.roleName) {
    // 无权限数据
    firstLoad = false
    store.dispatch('getUserInfo').then(res => {
      console.log(res)
    })
    next({ ...to }) // hack方法 、确保addRoutes已完成
  } else {
    // 有权限数据。因为是动态添加的路由，目前的路由都是有权限访问。无权限的对应的菜单路由不存在，都跳到404
    // if (to.meta.nav !== from.meta.nav) {
    //   console.log(1)
    //   // 切换顶部导航栏高亮
    //   store.dispatch('setCurNav', to.meta.nav).then(() => {
    //     next()
    //   })
    // } else {
      next()
    // }
    NProgress.done()
  }
})
router.afterEach(() => {
  NProgress.done() // finish progress bar
  // 路由完成后做些事情
})
