/**
 * 全局变量、系统配置。注册到vue里
 */
const mockLocal = false // 开关本地mock,调用真实接口时需要把它关了。mock分为两类1、本地mock（mockData.js） 2、接口mock(如网关提供的mock接口)
const gateway = false // 开关网关模式
const navOpen = false // 开关导航菜单
const frameLen = 5 // 默认缓存iframe domain最大个数的
const centerDepartId = '595739'
const pagination = {
  pageSizes: [10, 25, 50, 100],
  pageSize: 25,
  layout: 'total, sizes, prev, pager, next, jumper'
}
const modalTime = 250 // 模态框间隔时间
const appid = process.env.NODE_ENV === 'production' ? '' : 'FSTEST'
// 101货代维护状态,
const cFilter = {
  f101: {
    1: '停用',
    2: '启用'
  }
}
const cArr = {
  a101: [{
    label: '停用',
    value: 1
  },
  {
    label: '启用',
    value: 2
  }
  ]
}
const cols = {
  selection: 40,
  number: 60,
  op1: 80,
  op2: 100,
  op3: 125,
  op4: 160,
  huge: 270,
  larger: 160,
  big: 140,
  medium: 120,
  common: 100,
  small: 80,
  mini: 60
}
const fFormLabel = '75px'
const mFormLabel = '100px'
export default {
  mockLocal,
  gateway,
  navOpen,
  frameLen,
  pagination,
  modalTime,
  cFilter,
  cArr,
  cols,
  fFormLabel,
  mFormLabel,
  appid,
  centerDepartId
}
