import { getExecute } from '@/utils/dialogExecute'

export const rules = {
  name: [
    { required: true, message: '请输入商品名称', trigger: 'change' }
  ],
  type: [
    { required: true, message: '请输入商品类型', trigger: 'change' }
  ],
  price: [
    { required: true, message: '请输入价格', trigger: 'change' }
  ],
  cover: [
    { required: true, message: '请上传商品封面', trigger: 'change' }
  ],
  openTime: [
    { required: true, message: '请选择开放时间', trigger: 'change' }
  ],
  tag: [
    { required: true, message: '请输入商品标签', trigger: 'change' }
  ],
  supplierId: [
    { required: true, message: '请输入选择供应商', trigger: 'change' }
  ],
  description: [
    // { required: true, message: '请输入商品描述', trigger: 'change' }
  ],
  score: [
    // { required: true, message: '请输入商品评分', trigger: 'change' }
  ]
}
export const PRODUCT_TYPE = [
  {
    label: '场景',
    key: 'scene'
  },
  {
    label: '设备',
    key: 'device'
  },
  {
    label: '道具',
    key: 'prop'
  },
  {
    label: '演员',
    key: 'actor'
  },
  {
    label: '衣服',
    key: 'clothes'
  },
  {
    label: '住宿',
    key: 'hotel'
  },
  {
    label: '交通',
    key: 'traffic'
  },
  {
    label: '餐饮',
    key: 'food'
  }
]
export const productDetailRules = {
  image: [
    { required: true, message: '请上传商品明细', trigger: 'change' }
  ]
}

export const productRecommendRules = {
  orderNo: [
    { required: true, message: '请输入推荐序号', trigger: 'change' }
  ],
  productId: [
    { required: true, message: '请选择推荐的产品', trigger: 'change' }
  ]
}

export const productUsageRules = {
  name: [
    { required: true, message: '请输入过往剧集名称', trigger: 'change' }
  ],
  cover: [
    { required: true, message: '请上传过往剧集封面', trigger: 'change' }
  ]
}

export function resetProduct() {
  return {
    name: '',
    price: undefined,
    cover: '',
    type: '',
    openTime: '',
    tag: '',
    supplierId: '',
    description: '',
    score: '',
  }
}
export function resetProductDetail() {
  return {
    image: ''
  }
}
export function resetProductRecommend() {
  return {
    product: null,
    type: '',
    name: '', // 商品名称
    productId: '', // 商品id
    cover: '',
    orderNo: undefined // 商品推荐序号
  }
}

export function resetProductUsage() {
  return {
    name: '', // 剧集名称
    productId: '', // 商品id
    cover: '', // 剧集封面
  }
}

const singleVue = {
  addOrEditProduct: false,
  addOrEditProductDetail: false,
  addOrEditProductRecommend: false,
  addOrEditProductUsage: false,
  showImg: false
}

const strategyMap = {
  addOrEditProduct: () => import('./components/addOrEditProduct.vue'), // 日志显示
  addOrEditProductDetail: () => import('./components/addOrEditProductDetail.vue'), // 商品细节
  addOrEditProductRecommend: () => import('./components/addOrEditProductRecommend.vue'), // 商品推荐
  addOrEditProductUsage: () => import('./components/addOrEditProductUsage.vue'), // 商品 过往
  showImg: () => import('./components/showImg.vue') // 日志显示
}

export const execute = getExecute(singleVue, strategyMap)
