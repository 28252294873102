import httpService from '@/api/httpService'
import apiUrl from '@/api/apiUrl'

const state = {}
const getters = {}
const mutations = {}

const actions = {
  // 获取商品列表
  async getProductList({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.pageProduct,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 新增商品
  async saveProduct({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.saveProduct,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 更新商品
  async updateProduct({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.updateProduct,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 删除商品
  async deleteProduct({ commit, state }, params) {
    const url = `${apiUrl.deleteProduct.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.deleteProduct,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 根据id获取商品详情
  async getProductById({ commit, state }, params) {
    const url = `${apiUrl.getProductById.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.getProductById,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 获取商品细节列表
  async getProductDetailList({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.pageProductDetail,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 新增商品细节
  async saveProductDetail({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.saveProductDetail,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 更新商品细节
  async updateProductDetail({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.updateProductDetail,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 删除商品细节
  async deleteProductDetail({ commit, state }, params) {
    const url = `${apiUrl.deleteProductDetail.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.deleteProductDetail,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 根据id获取商品细节详情
  async getProductDetailById({ commit, state }, params) {
    const url = `${apiUrl.getProductDetailById.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.getProductDetailById,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 获取商品推荐列表
  async getProductRecommendList({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.pageProductRecommend,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 新增商品推荐
  async saveProductRecommend({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.saveProductRecommend,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 更新商品推荐
  async updateProductRecommend({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.updateProductRecommend,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 删除商品推荐
  async deleteProductRecommend({ commit, state }, params) {
    const url = `${apiUrl.deleteProductRecommend.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.deleteProductRecommend,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 根据id获取商品推荐详情
  async getProductRecommendById({ commit, state }, params) {
    const url = `${apiUrl.getProductRecommendById.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.getProductRecommendById,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 获取商品推荐列表
  async getProductUsageList({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.pageProductUsage,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 新增商品推荐
  async saveProductUsage({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.saveProductUsage,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 更新商品推荐
  async updateProductUsage({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.updateProductUsage,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 删除商品推荐
  async deleteProductUsage({ commit, state }, params) {
    const url = `${apiUrl.deleteProductUsage.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.deleteProductUsage,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 根据id获取商品推荐详情
  async getProductUsageById({ commit, state }, params) {
    const url = `${apiUrl.getProductUsageById.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.getProductUsageById,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  }
}
export default {
  state,
  actions,
  mutations,
  getters
}
