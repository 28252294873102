import Layout from '@/modules/layout/Layout.vue'
// 基础数据路由
export default {
  path: '/supportRecordManage',
  name: 'supportRecordManage',
  component: Layout,
  redirect: 'noredirect',
  icon: 'fa fa-envelope-o',
  meta: {
    label: '扶持记录管理'
  },
  children: [
    {
      path: 'supportRecordList',
      name: 'supportRecordList',
      component: () => import('@/views/supportRecordManage/supportRecordList.vue'),
      meta: {
        label: '扶持记录列表', // 标题
        closable: true
      }
    }
  ]
}
