<template>
  <div>
    <el-menu ref="menu"
             mode="vertical"
             :collapse="isCollapse"
             :unique-opened="uniqueOpened"
             class="el-menu-vertical"
             :default-active="defaultActive"
             :default-openeds="defaultOpeneds">
      <sub-menu :menus="menus"
                @executeRouter="executeRouter"></sub-menu>
    </el-menu>
  </div>
</template>
<script>
import SubMenu from './SubMenu'
export default {
  components: {
    SubMenu
  },
  props: {
    uniqueOpened: {
      type: Boolean,
      default: false
    },
    isCollapse: {
      type: Boolean,
      default: false
    },
    defaultActive: {
      type: String,
      default: ''
    },
    defaultOpeneds: {
      type: Array,
      default: function() {
        return []
      }
    },
    menus: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  watch: {
    defaultActive() {
      this.$refs.menu.activeIndex = this.defaultActive
    }
  },
  methods: {
    executeRouter(menu) {
      this.$emit('executeRouter', menu)
    }
  }
}
</script>
