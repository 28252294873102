import Layout from '@/modules/layout/Layout.vue'
// 基础数据路由
export default {
  path: '/caseManage',
  name: 'caseManage',
  component: Layout,
  redirect: 'noredirect',
  icon: 'fa fa-envelope-o',
  meta: {
    label: '备案管理'
  },
  children: [
    {
      path: 'caseList',
      name: 'caseList',
      component: () => import('@/views/caseManage/caseList.vue'),
      meta: {
        label: '备案列表', // 标题
        closable: true
      }
    }
  ]
}
