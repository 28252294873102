<template>
  <div v-if="GLOBAL.gateway">
    <template v-for="item in menus" v-if="!item.hidden">
      <el-submenu @click.native="executeRouter(item)" v-if="item.children.length > 0" :key="item.name" :index="item.name">
        <template slot="title"><i :class="item.menuIcon"></i><span slot="title">{{item.menuName}}</span></template>
        <sub-menu :menus="item.children" @executeRouter="executeRouter"></sub-menu>
      </el-submenu>
      <el-menu-item class="el-menu-noClidren" @click="executeRouter(item)" :index="item.name" :key="item.name" v-else><i :class="item.menuIcon"></i><span slot="title">{{item.menuName}}</span></el-menu-item>
    </template>
  </div>
  <div v-else>
    <template v-for="item in menus" v-if="!item.hidden&&item.subMenus.length>0">
      <el-menu-item v-if="item.noMe && item.subMenus[0] && item.subMenus[0].noMe && item.subMenus[0].subMenus[0] && !item.subMenus[0].subMenus[0].noMe" @click="executeRouter(item.subMenus[0].subMenus[0])" :index="item.subMenus[0].subMenus[0].name" :key="item.subMenus[0].subMenus[0].name" class="el-menu-noClidren">
        <i :class="item.subMenus[0].subMenus[0].icon"></i>
        <span slot="title">{{item.subMenus[0].subMenus[0].title}}</span>
      </el-menu-item>
      <el-menu-item v-else-if="item.noMe && item.subMenus[0]&&!item.subMenus[0].noMe" class="el-menu-noClidren" v-for="_item in item.subMenus" @click="executeRouter(_item)" :index="_item.name" :key="_item.name">
        <i :class="_item.icon"></i>
        <span slot="title">{{_item.title}}</span>
      </el-menu-item>
      <el-submenu v-else @click.native="executeRouter(item)" :key="item.name" :index="item.name">
        <template slot="title">
          <i :class="item.icon"></i>
          <span slot="title">{{item.title}}</span>
        </template>
        <template v-for="child in item.subMenus" v-if="!child.hidden">
          <sub-menu v-if="child.subMenus[0]&&!child.subMenus[0].noMe" :menus="[child]" @executeRouter="executeRouter" :key="child.name"></sub-menu>
          <el-menu-item v-else :index="child.name" :key="child.name" @click.native="executeRouter(child)">
            <span v-if="child.title">{{child.title}}</span>
          </el-menu-item>
        </template>
      </el-submenu>
    </template>
  </div>
</template>
<script>
export default {
  name: 'subMenu',
  props: {
    menus: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {
  },
  methods: {
    executeRouter(menu) {
      this.$emit('executeRouter', menu)
    }
  }
}
</script>
