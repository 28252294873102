import http from '@/utils/http'
import { util } from '@/utils/util'
import store from '@/store/store'
function uuid() {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  // bits 12-15 of the time_hi_and_version field to 0010
  s[14] = '4'
  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = '-'
  const uuid = s.join('')
  return uuid
}
export default {
  accessAPI: function(opts) {
    const apiObj = opts.apiObj || {}
    const _method = apiObj.method
    const _body = opts.body || {}
    const _path = opts.path
    const _query = {}
    // const _headers = opts.headers || {}
    const _headers = {
      ...opts.headers,
      Authorization: store.getters.token,
      request_code: uuid()
    }

    let apiUrl = apiObj.url
    if (util.isObject(_path)) {
      Object.keys(_path).forEach(key => {
        apiUrl = apiUrl.replace(':' + key, _path[key])
      })
    }
    if (opts.query && !util.isEmptyObject(opts.query)) {
      Object.keys(opts.query).forEach(key => {
        if (opts.query[key] !== '' && opts.query[key] !== undefined && opts.query[key] !== null) {
          _query[key] = opts.query[key]
        }
      })
    }
    return http({
      url: apiUrl,
      method: _method,
      params: _query,
      data: _body,
      headers: _headers
    })
  }
}
