import Layout from '@/modules/layout/Layout.vue'
// 基础数据路由
export default {
    path: '/supplierManage',
    name: 'supplierManage',
    component: Layout,
    redirect: 'noredirect',
    icon: 'fa fa-envelope-o',
    meta: {
        label: '供应商管理'
    },
    children: [
        // {
        //     path: 'addSupplier',
        //     name: 'addSupplier',
        //     component: () => import('@/views/supplierManage/addSupplier'),
        //     meta: {
        //         label: '新增供应商', // 标题
        //         closable: true
        //     }
        // },
        {
            path: 'supplierList',
            name: 'supplierList',
            component: () => import('@/views/supplierManage/supplierList'),
            meta: {
                label: '供应商列表', // 标题
                closable: true
            }
        }
    ]
}
