import httpService from '@/api/httpService'
import apiUrl from '@/api/apiUrl'

const state = {}
const getters = {}
const mutations = {}

const actions = {
  // 获取资讯列表
  async getPolicyList({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.pagePolicy,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 新增资讯
  async savePolicy({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.savePolicy,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 更新资讯
  async updatePolicy({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.updatePolicy,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 删除资讯
  async deletePolicy({ commit, state }, params) {
    const url = `${apiUrl.deletePolicy.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.deletePolicy,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 根据id获取资讯详情
  async getPolicyById({ commit, state }, params) {
    const url = `${apiUrl.getPolicyById.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.getPolicyById,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  }
}
export default {
  state,
  actions,
  mutations,
  getters
}
