<template>
  <header id="header" class="zto-header-old">
    <div class="header-left">
      <div class="date-content">
        <span class="dateTime">{{curDate}}</span>
      </div>
      <div class="vertical-line"></div>
      <div class="user-content">
<!--        <span class="userName">{{userInfo.fullname}}</span> - -->
<!--        <span class="department">{{userInfo.deptName}}</span>-->
      </div>
    </div>
    <div class="marqueeWrap">
      <marquee>{{notice}}</marquee>
    </div>
    <div class="header-right">
      <div class="contact-content">
        <el-popover placement="bottom"
                    width="80"
                    trigger="click"
                    v-model="visible1"
                    popper-class="exitPopover">
          <span class="userMes" slot="reference">
            <i class="fa fa-question-circle-o" style="font-size: 16px;"></i>
            <span class="popMes">帮助</span>
          </span>
        </el-popover>
        <div class="vertical-line"></div>
        <el-popover
          placement="bottom"
          width="80"
          trigger="click"
          popper-class="exitPopover"
          v-model="visible">
          <el-button type="text" class="exitBtn" icon="fa fa-sign-out" @click="logoutAction" >退出</el-button>
          <a href="javascript:void(0)" :title="userInfo.fullname + ' ' +userInfo.deptName" slot="reference" class="userMes">
            <img :src="userInfo.avatar" class="userAvatar" alt="">
            {{userInfo.fullname}}
          </a>
        </el-popover>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { util } from '@/utils/util'
import { projectAddRule } from 'config/validateRules'

export default {
  name: 'ztoHeader',
  data() {
    return {
      curDate: '',
      notice: '',
      visible3: false,
      visible2: false,
      visible1: false,
      visible: false,
      projectAddRule,
      dialogVisible: {
        task: false,
        version: false,
        project: false
      }
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    })
  },
  created() {
    this.curDate = util.getCurDateTimeWeek()
    setInterval(
      function() {
        this.curDate = util.getCurDateTimeWeek()
      }.bind(this),
      1000
    )
  },
  methods: {
    ...mapActions(['clearZtoSession', 'getNotice']),
    logoutAction() {
      this.$confirm('您确定要退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          this.clearZtoSession().then().finally(() => {
            this.$router.push({ path: '/login' })
          })
        }, () => {
        })
    }
  }
}
</script>
<style>
  @import "../../../scss/customerIcon.scss";
</style>
<style scope lang="scss">
  #header {
    display: flex;
  }
  .marqueeWrap {
    padding: 0 20px 0 0;
    overflow: hidden;
    flex-grow: 1;
  }
  .header-left, .header-right {
    flex-shrink: 0;
  }
  .userMes{
    cursor: pointer;
    margin: 0 5px;
    position: relative;
    .popMes {
      position: absolute;
      left: 10px;
      top: 20px;
      background-color: #fff;
      border: 1px solid #888888;
      color: #333333;
      width: 55px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 3px;
      z-index: 10000;
      display: none;
    }
    &:hover{
      .popMes {
        display: block;
      }
    }
  }

  .userMes:before{
    content: '';
    display: inline-block;
    clear: both;
  }
  .userAvatar {
    float: left;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    margin-top: 8px;
    margin-right: 5px;
  }
  .popoverContent{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .btnItem {
    border: 1px solid #cccccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin: 8px;
    border-radius: 5px;
    color: #0d0d0d;
  }
  .btnItem:hover{
    background-color: #048cff;
    color: #ffffff;
    border-color: #ffffff;
  }
  .createItemData{
    margin: 0;
    /*padding-left: 10px;*/
    padding-left: 0;
  }
  .createItem{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    padding: 0 6px;
    color: #0d0d0d;
  }
  .createItem:hover{
    background-color: #04a3ff;
    color: #ffffff;
  }
  .createItem .imageIcon{
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    margin-right: 6px;
  }
</style>
