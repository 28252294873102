import httpService from '@/api/httpService'
import apiUrl from '@/api/apiUrl'

const state = {}
const getters = {}
const mutations = {}

const actions = {
  // 获取供应商列表
  async getSupplierList({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.pageSupplier,
      body: params
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 新增供应商
  async saveSupplier({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.saveSupplier,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 更新供应商
  async updateSupplier({ commit, state }, params) {
    const opts = {
      apiObj: apiUrl.updateSupplier,
      body: params
    }
    const { result } = await httpService.accessAPI(opts)
    return result
  },
  // 删除供应商
  async deleteSupplier({ commit, state }, params) {
    const url = `${apiUrl.deleteSupplier.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.deleteSupplier,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  },
  // 根据id获取供应商详情
  async getSupplierById({ commit, state }, params) {
    const url = `${apiUrl.getSupplierById.url}/${params}`
    const opts = {
      apiObj: {
        ...apiUrl.getSupplierById,
        url
      }
    }
    const { data } = await httpService.accessAPI(opts)
    return data
  }
}
export default {
  state,
  actions,
  mutations,
  getters
}
