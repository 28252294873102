import router from '@/router/routes'
import { tool } from '@/router/tool'
const Types = {
  ADD_PAGE_DETAIL: 'ADD_PAGE_DETAIL',
  SET_SKIN: 'SET_SKIN'
}
const state = {
  // 皮肤版本
  skin: 'v1'
}
const getters = {
  skin: state => state.skin
}
const mutations = {
  [Types.ADD_PAGE_DETAIL](state, result) {},
  [Types.SET_SKIN](state, params) {
    state.skin = params
  }
}
const actions = {
  addPageDetail({ commit, state, rootState }, params) {
    return new Promise((resolve, reject) => {
      var routes = []
      var obj = {}
      routes.push(tool.pageDetail(params, obj, 1, rootState.user.routeDeep))
      router.addRoutes(routes)
      resolve()
    })
  },
  setSkin({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit(Types.SET_SKIN, params)
      resolve()
    })
  }
}
export default {
  state,
  actions,
  mutations,
  getters
}
